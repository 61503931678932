import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

// Functions
import { checkPermission, setTitle } from 'Util';

// Components
import ReportsBaseLayout from '../components/BaseLayout';
import Overview from './components/Overview';
import BsrUsers from './components/BsrUsers';
import BsrUsersParameters from './components/Parameters/BsrUsersParams';
import Progress from './components/Progress';
import ProgressParameters from './components/Parameters/ProgressParameters';
import DataViewer from './components/DataViewer';
import Summaries from './components/Summaries';
import BsrCoursesEnrollment from './components/BsrCoursesEnrollment';
import BsrCoursesParameters from './components/Parameters/BsrCoursesParameters';
import CandidatesLatestStatuses from './components/CandidatesLatestStatuses';
import Agreements from './components/Agreements';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function CandidatesReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);

  const [selected, setSelected] = useState({ value: '' });
  const [openParams, setOpenParams] = useState(false);
  const [parameters, setParameters] = useState(null);

  setTitle('Candidates Reports');

  const openParameters = () => {
    setOpenParams(true);
  };

  const closeParameters = () => {
    setOpenParams(false);
  };

  const handleParameters = (params) => {
    setParameters(params);
  };

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R001', currentUser)) {
      r.push({
        value: 'overview',
        label: t('overview'),
        parameters: false,
        defaultParameters: undefined,
      });
    }
    if (checkPermission('R002', currentUser)) {
      r.push({
        value: 'progress',
        label: t('progress'),
        parameters: true,
        defaultParameters: { statuses: [2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17] },
      });
    }
    if (checkPermission('R003', currentUser)) {
      r.push({
        value: 'dataview',
        label: t('data-viewer'),
        parameters: false,
        defaultParameters: undefined,
      });
    }
    if (checkPermission('R004', currentUser)) {
      r.push({
        value: 'bsr-users',
        label: t('bsr-users'),
        parameters: true,
        defaultParameters: { tags: ['NASH'] },
      });
    }
    if (checkPermission('R005', currentUser)) {
      r.push({
        value: 'bsr-courses',
        label: t('bsr-courses'),
        parameters: true,
        defaultParameters: { course: undefined },
      });
    }
    if (checkPermission('R006', currentUser)) {
      r.push({
        value: 'summary',
        label: t('summary'),
        parameters: false,
        defaultParameters: undefined,
      });
    }
    if (checkPermission('R007', currentUser)) {
      r.push({
        value: 'candidates-statuses',
        label: t('candidates-statuses'),
        parameters: false,
        defaultParameters: undefined,
      });
    }
    if (checkPermission('R008', currentUser)) {
      r.push({
        value: 'agreements',
        label: t('agreements'),
        parameters: false,
        defaultParameters: undefined,
      });
    }

    return r;
  }, [currentUser, t]);

  return (
    <ReportsBaseLayout
      reports={reports}
      selectReport={setSelected}
      showParameters={selected.parameters}
      openParamsFunc={openParameters}
    >
      {selected.value === 'overview' && <Overview />}
      {selected.value === 'progress' && (
        <div>
          <Progress parameters={parameters !== null ? parameters : selected.defaultParameters} />
          <ProgressParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'dataview' && <DataViewer />}
      {selected.value === 'bsr-users' && (
        <div>
          <BsrUsers parameters={parameters !== null ? parameters : selected.defaultParameters} />
          <BsrUsersParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'bsr-courses' && (
        <div>
          <BsrCoursesEnrollment
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <BsrCoursesParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'summary' && <Summaries />}
      {selected.value === 'candidates-statuses' && <CandidatesLatestStatuses />}
      {selected.value === 'agreements' && <Agreements />}
    </ReportsBaseLayout>
  );
}

export default CandidatesReports;
