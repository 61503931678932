import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';

function FlatInfoCard({ icon, title, subtitle }) {
  return (
    <Card sx={{ display: 'flex', padding: '5px' }}>
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        p={1}
      >
        <Grid item md={12}>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon color="secondary" fontSize="large">{icon}</Icon>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SuiTypography variant="h4">{title}</SuiTypography>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <SuiTypography variant="subtitle2" fontWeight="light">{subtitle}</SuiTypography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

FlatInfoCard.defaultProps = {
  subtitle: '',
};

FlatInfoCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default FlatInfoCard;
