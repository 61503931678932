import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchDueInvoices } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components
import PivotGrid, { FieldChooser, Export, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Tooltip,
} from 'devextreme-react/chart';
import {
  DataGrid, Column, Paging, Pager,
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';

// Other components
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';

// Functions
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  dueInvoices: state.reports.dueInvoices,
});

function DueInvoices({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { dueInvoices } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const pivotGrid = useRef(null);
  const dataGridRef = useRef(null);
  const chart = useRef(null);

  const [parentHeight, setParentHeight] = useState(500);
  const [reportData, setReportData] = useState([]);
  const [popupTitle, setPopupTitle] = useState('');
  const [drillDownDataSource, setDrillDownDataSource] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - 150);
  }, [height]);

  useEffect(() => {
    if (parameters !== undefined && parameters !== null) {
      const { date } = parameters;
      if (date !== undefined && date !== null) {
        dispatch(fetchDueInvoices({ date: parameters.date.replace(/-/g, '') }));
      }
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('age'),
          dataField: 'group_days',
          area: 'column',
        },
        {
          caption: t('candidate'),
          dataField: 'candidate_name',
          width: 200,
          area: 'row',
        },
        {
          caption: t('advisor'),
          dataField: 'advisor_name',
          width: 200,
          area: 'row',
        },
        {
          dataField: 'candidate_uuid',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'advisor_uuid',
          area: 'column',
          visible: false,
        },
        {
          caption: t('total'),
          dataField: 'invoice_balance',
          summaryType: 'count',
          area: 'data',
        },
        {
          caption: t('balance'),
          dataField: 'invoice_balance',
          dataType: 'number',
          summaryType: 'sum',
          format: 'currency',
          area: 'data',
        },
      ],
      store: dueInvoices,
    });

    setReportData(dataSource);

    pivotGrid.current.instance.bindChart(chart.current.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });
  }, [dueInvoices, t]);

  const customizeTooltip = (args) => {
    const valueText = args.originalValue;
    return {
      html: `${args.seriesName} | Total ${valueText}`,
    };
  };

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('DueInvoices');

    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DueInvoices.xlsx');
      });
    });
  };

  const onCellClick = (e) => {
    if (e.area === 'data') {
      const pivotGridDataSource = e.component.getDataSource();
      const rowPathLength = e.cell.rowPath.length;
      const rowPathName = e.cell.rowPath[rowPathLength - 1];

      setPopupTitle(rowPathName);
      setDrillDownDataSource(pivotGridDataSource.createDrillDownDataSource(e.cell));
      setPopupVisible(true);
    }
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={1} />
      <Grid item md={10}>
        <Card>
          <Grid containe m={3}>
            <Grid item md={12}>
              <Chart ref={chart}>
                <CommonSeriesSettings
                  type="bar"
                />
                <AdaptiveLayout width={450} />
                <Tooltip enabled customizeTooltip={customizeTooltip} />
              </Chart>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={1} />
      <Grid item md={12}>
        <Card>
          <Grid item m={3} md={12}>
            <PivotGrid
              id="due-invoices"
              dataSource={reportData}
              allowSortingBySummary
              allowFiltering
              showBorders={false}
              showRowTotals
              showRowGrandTotals
              ref={pivotGrid}
              onExporting={onExporting}
              height={parentHeight}
              onCellClick={onCellClick}
            >
              <FieldChooser enabled height={400} />
              <Export enabled />
              <Scrolling mode="virtual" />
            </PivotGrid>
            <Popup
              visible={popupVisible}
              title={popupTitle}
              onHiding={() => setPopupVisible(false)}
              onShown={() => dataGridRef.current.instance.updateDimensions()}
              showCloseButton
            >
              <DataGrid
                dataSource={drillDownDataSource}
                ref={dataGridRef}
              >
                <Paging defaultPageSize={30} />
                <Pager
                  visible
                  allowedPageSizes={[10, 30, 50, 'all']}
                  displayMode="full"
                  showPageSizeSelector
                  showInfo
                  showNavigationButtons
                />
                <Column dataField="invoice_number" caption={t('invoice-number')} />
                <Column dataField="invoice_date" caption={t('invoice-date')} dataType="date" defaultSortOrder="asc" />
                <Column dataField="invoice_due" caption={t('invoice-due')} dataType="date" defaultSortOrder="asc" />
                <Column dataField="invoice_total" caption={t('invoice-total')} dataType="number" format="currency" />
                <Column dataField="invoice_balance" caption={t('invoice-balance')} dataType="number" format="currency" />
                <Column dataField="past_due_days" caption={t('past-due-days')} dataType="number" />
              </DataGrid>
            </Popup>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

DueInvoices.propTypes = {
  parameters: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }).isRequired,
};

export default DueInvoices;
