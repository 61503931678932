import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Reducer functions
import { fetchRecruitmentJobApplications } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { setTitle } from 'Util';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  recruitmentJobApplications: state.reports.recruitmentJobApplications,
});

function JobApplications({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { recruitmentJobApplications } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  setTitle('Recruitment Job Applications Report');

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchRecruitmentJobApplications({ statuses: parameters.statuses }));
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    if (recruitmentJobApplications !== undefined) {
      setData(recruitmentJobApplications);
    }
  }, [recruitmentJobApplications]);

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  const columns = [
    {
      caption: t('name', { keyPrefix: 'common' }),
      field: 'full_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('first-name', { keyPrefix: 'common' }),
      field: 'first_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('last-name', { keyPrefix: 'common' }),
      field: 'last_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('status', { keyPrefix: 'common' }),
      field: 'candidate_status',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('country'),
      field: 'country_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('advisor'),
      field: 'current_advisor_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('position'),
      field: 'position_listing',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('client'),
      field: 'client',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('introductory-meeting'),
      field: 'introductory_meeting_date',
      calculateCellValue: (d) => handleDateFormat(d.introductory_meeting_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('interview-date'),
      field: 'interview_date',
      calculateCellValue: (d) => handleDateFormat(d.interview_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('offered-employment'),
      field: 'offer_received_date',
      calculateCellValue: (d) => handleDateFormat(d.offer_received_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('accepted-employment'),
      field: 'offer_acceptance_date',
      calculateCellValue: (d) => handleDateFormat(d.offer_acceptance_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('rejected-job-offer'),
      field: 'rejected_on',
      calculateCellValue: (d) => handleDateFormat(d.rejected_on),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('priority-date'),
      field: 'priority_date',
      calculateCellValue: (d) => handleDateFormat(d.priority_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('visa-screen-expiration'),
      field: 'visa_screen_expiration',
      calculateCellValue: (d) => handleDateFormat(d.visa_screen_expiration),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-expiration'),
      field: 'license_expiration',
      calculateCellValue: (d) => handleDateFormat(d.license_expiration),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('passport-expiration'),
      field: 'passport_date_expiration',
      calculateCellValue: (d) => handleDateFormat(d.passport_date_expiration),
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={12}>
        <BaseTable name="RecruitmentJobApplications" data={data} keyExpr="uuid" columns={columns} />
      </Grid>
    </Grid>
  );
}

JobApplications.propTypes = {
  parameters: PropTypes.shape({
    statuses: PropTypes.arrayOf(PropTypes.number.isRequired),
  }).isRequired,
};

export default JobApplications;
