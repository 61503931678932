import { forwardRef } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// eslint-disable-next-line react/display-name
const DefaultItem = forwardRef(
  ({
    icon, title, description, ...rest
  }, ref) => (
    <SuiBox {...rest} ref={ref} display="flex" alignItems="center" mt={1}>
      <Icon
        fontSize="small"
        color="secondary"
      >
        {icon}
      </Icon>
      <SuiBox ml={2} lineHeight={1}>
        <SuiTypography display="block" variant="button" fontWeight="medium">
          {title}
        </SuiTypography>
        <SuiTypography variant="button" fontWeight="regular" color="text">
          {description}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  ),
);

// Typechecking props for the DefaultItem
DefaultItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DefaultItem;
