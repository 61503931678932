import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Utils
import { defaultValue, findSelectValue } from 'Util';
import { useEffect, useState } from 'react';

function SimplifyNclexCard({ i, c, ops }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nclex' });

  const [country, setCountry] = useState('');

  useEffect(() => {
    if (c.country_id !== undefined && c.country_id !== null) {
      const countryVal = findSelectValue(ops.countries, c.country_id);

      if (countryVal !== undefined) {
        setCountry(countryVal.name);
      }
    }
  }, [ops, c]);

  const testResult = [
    {
      value: 1,
      label: t('passed'),
    },
    {
      value: 2,
      label: t('failed'),
    },
    {
      value: 3,
      label: t('scheduled'),
    },
  ];

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        title={`${t('nclex')} ${i + 1}`}
      />
      <CardContent>
        <SuiBox mb={2}>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <SuiTypography variant="body1" fontWeight="bold">{t('schedule')}</SuiTypography>
            </Grid>
            <Grid item md={4}>
              <SuiTypography variant="body1" fontWeight="bold">{t('country')}</SuiTypography>
            </Grid>
            <Grid item md={4}>
              <SuiTypography variant="body1" fontWeight="bold">{t('result')}</SuiTypography>
            </Grid>
            <Grid item md={4}>
              {defaultValue(c.schedule)}
            </Grid>
            <Grid item md={4}>
              {country}
            </Grid>
            <Grid item md={4}>
              {findSelectValue(testResult, c.nclex_result_id).label}
            </Grid>
          </Grid>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

SimplifyNclexCard.propTypes = {
  i: PropTypes.number.isRequired,
  c: PropTypes.shape(
    {
      att_number: PropTypes.string,
      att_received: PropTypes.string,
      att_expiration: PropTypes.string,
      file_url: PropTypes.string,
      nclex_result_id: PropTypes.number,
      schedule: PropTypes.string,
      country_id: PropTypes.number,
      id: PropTypes.number,
    },
  ).isRequired,
  ops: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default SimplifyNclexCard;
