import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  fetchCandidateNclexAssessmentTests,
  fetchCandidateNclexTests,
  fetchCandidateNursingBoards, setCurrentSubView,
  setNclexAssessmentTests,
} from 'reducers/candidatesSlice';
import { fetchCountries, fetchStates } from 'reducers/commonSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Functions
import { getCandidateUUID, isValidValue } from 'Util';

// Components
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SimplifyNursingBoardCard
  from 'layouts/components/Cards/NursingBoardCard/SimplifyNursingBoardCard';
import SimplifyNclexCard from 'layouts/components/Cards/NclexCards/SimplifyNclexCard';
import DefaultNclexAssessmentCard
  from 'layouts/components/Cards/NclexCards/DefaultNclexAssessmentCard';
import SavePanel from '../SavePanel';

const sliderStyle = { 'min-height': '0', 'min-width': '0', padding: '25px' };
const sliderSettings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const selector = (state) => ({
  editing: state.candidate.editing,
  countries: state.common.countries,
  states: state.common.states,
  nursingBoards: state.candidate.nursingBoards,
  nclexTests: state.candidate.nclexTests,
  nclexAssessmentTests: state.candidate.nclexAssessmentTests,
});

function Coaching() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.coaching' });
  const {
    editing,
    countries,
    states,
    nursingBoards,
    nclexTests,
    nclexAssessmentTests,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('coaching'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchStates({ id: 233 }));

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateNursingBoards({ uuid }));
      dispatch(fetchCandidateNclexTests({ uuid }));
      dispatch(fetchCandidateNclexAssessmentTests({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  const handleAddNclexAssessment = () => {
    dispatch(
      setNclexAssessmentTests(
        nclexAssessmentTests.concat({
          id: undefined,
          date: '',
          score: '',
          result: '',
          questions: 0,
          challenging_topic_id: undefined,
          nclex_readiness_id: undefined,
          changed: true,
          valid: false,
        }),
      ),
    );
  };

  const isValidAssessment = (key, value, r) => {
    const objToValidate = {
      ...r,
      [key]: value,
    };

    return isValidValue(objToValidate.date)
      && isValidValue(objToValidate.result)
      && isValidValue(objToValidate.questions)
      && isValidValue(objToValidate.nclex_readiness_id);
  };

  const setAssessmentValue = (key, value, i) => {
    dispatch(
      setNclexAssessmentTests(
        nclexAssessmentTests.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValidAssessment(key, value, nclexAssessmentTests[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleRemoveAssessment = (i) => {
    if (nclexAssessmentTests[i].id !== undefined) {
      setAssessmentValue('_destroy', true, i);
    } else {
      dispatch(
        setNclexAssessmentTests([
          ...nclexAssessmentTests.slice(0, i),
          ...nclexAssessmentTests.slice(i + 1),
        ]),
      );
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('educational-materials', { keyPrefix: 'candidates.nclex' })}
            </SuiTypography>
          </Grid>
          <Grid item md={6}>
            <Card sx={{ overflow: 'visible' }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={12} mt={1}>
                    <SuiTypography
                      variant="h5"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('archer', { keyPrefix: 'candidates.nclex' })}
                    </SuiTypography>
                  </Grid>
                  <Grid item md={6}>
                    <SuiTypography variant="body1" fontWeight="bold">{t('date')}</SuiTypography>
                  </Grid>
                  <Grid item md={6}>
                    <SuiTypography variant="body1" fontWeight="bold">{t('expiration')}</SuiTypography>
                  </Grid>
                  <Grid item md={6}>
                    {t('date')}
                  </Grid>
                  <Grid item md={6}>
                    {t('expiration')}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card sx={{ overflow: 'visible' }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={12} mt={1}>
                    <SuiTypography
                      variant="h5"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {t('saunders', { keyPrefix: 'candidates.nclex' })}
                    </SuiTypography>
                  </Grid>
                  <Grid item md={6}>
                    <SuiTypography variant="body1" fontWeight="bold">{t('date')}</SuiTypography>
                  </Grid>
                  <Grid item md={6}>
                    <SuiTypography variant="body1" fontWeight="bold">{t('expiration')}</SuiTypography>
                  </Grid>
                  <Grid item md={6}>
                    {t('date')}
                  </Grid>
                  <Grid item md={6}>
                    {t('expiration')}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} mt={1}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('board-of-nursing')}
            </SuiTypography>
          </Grid>
          <Grid item md={12}>
            <div style={sliderStyle}>
              <Slider {...sliderSettings}>
                {Object.keys(nursingBoards).length > 0
                  && nursingBoards.map((b, i) => (
                    <div>
                      <SimplifyNursingBoardCard e={b} i={i} ops={{ states }} />
                    </div>
                  ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} mt={1}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('nclex')}
            </SuiTypography>
          </Grid>
          <Grid item md={12}>
            <div style={sliderStyle}>
              <Slider {...sliderSettings}>
                {Object.keys(nclexTests).length > 0
                  && nclexTests.map((n, i) => (
                    <div>
                      <SimplifyNclexCard c={n} i={i} ops={{ countries }} />
                    </div>
                  ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('nclex-assessment-tests', { keyPrefix: 'candidates.nclex' })}
            </SuiTypography>
            <SuiBox mt={1} mb={2}>
              <SuiTypography variant="body2" color="text">
                {t('nclex-assessment-tests-description', { keyPrefix: 'candidates.nclex' })}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item md={12} mb={3}>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddNclexAssessment}
              >
                {t('new-nclex-assessment', { keyPrefix: 'candidates.nclex' })}
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <SuiBox pb={1} px={1}>
              {Object.keys(nclexAssessmentTests).length > 0
                && nclexAssessmentTests
                  // eslint-disable-next-line no-underscore-dangle
                  .filter((r) => r._destroy !== true)
                  .map((n, i) => (
                    <div key={`nclex-assessment-test-${i}`}>
                      <DefaultNclexAssessmentCard
                        i={i}
                        c={n}
                        setValue={setAssessmentValue}
                        removeFunc={handleRemoveAssessment}
                      />
                      <Divider />
                    </div>
                  ))}
            </SuiBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <SavePanel />
      </Grid>
    </Grid>
  );
}

export default Coaching;
