import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer Functions
import { matchBsrCandidates, setBsrCandidatesMatches } from 'reducers/reportsSlice';
import { fetchCourseUsers } from 'reducers/bsrSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// Functions
import { setTitle, timestampToDate } from 'Util';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  courses: state.bsr.courses,
  matches: state.reports.bsrCandidatesMatches,
});

function BsrCoursesEnrollment({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    courses,
    matches,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('BSR Courses Enrolment Report');

  const [bsrCourses, setBsrCourses] = useState([]);
  const [coursesPage, setCoursesPage] = useState(1);
  const [matched, setMatched] = useState(false);

  useEffect(() => {
    if (courses !== undefined && courses.meta !== undefined && parameters.course !== undefined) {
      if (courses.meta.page >= coursesPage) {
        setBsrCourses(bsrCourses.concat(courses.data));
        setCoursesPage(courses.meta.page);
      }

      if (courses.meta.page < courses.meta.totalPages) {
        dispatch(fetchCourseUsers({
          id: parameters.course,
          page: courses.meta.page + 1,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courses]);

  setTitle('BSR Courses Report');

  useEffect(() => {
    if (parameters.course !== undefined) {
      setBsrCourses([]);
      setCoursesPage(1);
      setMatched(false);
      dispatch(setBsrCandidatesMatches([]));
      dispatch(fetchCourseUsers({
        id: parameters.course,
        page: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, parameters]);

  useEffect(() => {
    if (matches.length > 0) {
      setMatched(true);
      setBsrCourses(matches);
    }
  }, [matches]);

  const handleMatch = () => {
    if (bsrCourses !== undefined) {
      if (bsrCourses.length > 0) {
        dispatch(matchBsrCandidates({ candidates: bsrCourses }));
      }
    }
  };

  const columns = [
    {
      field: 'username',
      caption: t('username'),
    },
    {
      field: 'email',
      caption: t('e-mail', { keyPrefix: 'candidates.contact-info' }),
    },
    {
      field: 'fields.country',
      caption: t('country'),
    },
    {
      field: 'fields.phone',
      caption: t('phone', { keyPrefix: 'candidates.contact-info' }),
    },
    {
      field: 'created',
      caption: t('created'),
      dataType: 'datetime',
      calculateCellValue: (data) => timestampToDate(data.created),
    },
    {
      field: 'fields.last_login',
      caption: t('last-login'),
      dataType: 'datetime',
      calculateCellValue: (data) => timestampToDate(data.last_login),
    },
  ];

  if (matched) {
    columns.push({
      field: 'candidate_name',
      caption: t('candidate-name'),
    });
    columns.push({
      field: 'candidate_status',
      caption: t('status'),
    });
    columns.push({
      field: 'candidate_language_level',
      caption: t('english-level'),
    });
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
    >
      <Grid item md={12}>
        {(bsrCourses.length > 0 && !matched) && (
          <Grid
            container
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <SuiButton
                variant="contained"
                color="info"
                onClick={handleMatch}
              >
                {t('match-candidates')}
              </SuiButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={12}>
        <BaseTable name="BSRCourses" data={bsrCourses} keyExpr="id" columns={columns} />
      </Grid>
    </Grid>
  );
}

BsrCoursesEnrollment.propTypes = {
  parameters: PropTypes.instanceOf({
    course: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
};

export default BsrCoursesEnrollment;
