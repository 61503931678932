import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchCandidateStatuses } from 'reducers/commonSlice';

// Functions
import { findSelectValue } from 'Util';

// @mui material components
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import BaseParameters from 'layouts/reports/components/BaseParameters';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 1,
};

const selector = (state) => ({
  candidateStatuses: state.common.candidateStatuses,
});

function EnglishLearningSpanParameters({
  parameters,
  handleParamsFunc,
  openParameters,
  closeParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { candidateStatuses } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [selectedCandidateStatuses, setSelectedCandidateStatuses] = useState([]);
  const [params, setParams] = useState(parameters);

  useEffect(() => {
    dispatch(fetchCandidateStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (parameters !== undefined) {
      const { statuses } = parameters;
      const sel = [];
      if (statuses === null) {
        if (candidateStatuses !== undefined) {
          candidateStatuses
            .forEach((s) => sel.push(findSelectValue(candidateStatuses, s.id)));
          setSelectedCandidateStatuses(sel);
        }
      } else if (statuses !== undefined) {
        statuses
          .forEach((id) => sel.push(findSelectValue(candidateStatuses, id)));
        setSelectedCandidateStatuses(sel);
      }
    }
  }, [dispatch, parameters, candidateStatuses]);

  const handleCandidateStatuses = (newItems) => {
    setSelectedCandidateStatuses(newItems);
    setParams({
      statuses: newItems.map((s) => s.id),
    });
  };

  return (
    <BaseParameters
      parameters={params}
      style={style}
      openParameters={openParameters}
      closeParamsFunc={closeParamsFunc}
      handleParamsFunc={(p) => handleParamsFunc(p)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SuiBox mb={0.5} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t('candidate-statuses')}
              </SuiTypography>
            </SuiBox>
            <Autocomplete
              multiple
              id="candidate-statuses"
              options={candidateStatuses}
              getOptionLabel={(option) => option.label}
              value={selectedCandidateStatuses}
              filterSelectedOptions
              renderInput={(s) => (
                <TextField
                  {...s}
                  variant="outlined"
                  placeholder={t('candidate-statuses')}
                />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  handleCandidateStatuses(newValue);
                } else if (newValue && newValue.inputValue) {
                  handleCandidateStatuses(newValue.inputValue);
                } else {
                  handleCandidateStatuses(newValue);
                }
              }}
            />
          </SuiBox>
        </Grid>
      </Grid>
    </BaseParameters>
  );
}

EnglishLearningSpanParameters.propTypes = {
  parameters: PropTypes.shape({
    statuses: PropTypes.string,
  }).isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
};

export default EnglishLearningSpanParameters;
