import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  fetchUserPermissions,
  fetchUserProfile,
  setNewPassword,
  setUserEditing,
  setUserProfile,
} from 'reducers/usersSlice';
import { setUser } from 'reducers/authSlice';

// @material-ui core components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

// Functions
import { setTitle } from 'Util';

// Layout components
import BaseLayout from 'layouts/components/BaseLayout';
import Options from 'layouts/candidates/components/Options';
import Header from './components/Header';
import BasicInfo from './components/BasicInfo';
import ChangePassword from './components/ChangePassword';
import Notifications from './components/Notifications';
import SavePanel from './components/SavePanel';

const selector = (state) => ({
  user: state.auth.user,
  userProfile: state.user.userProfile,
  newPassword: state.user.newPassword,
  permissions: state.user.permissions,
});

function UserSettings() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const {
    user,
    userProfile,
    newPassword,
    permissions,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserEditing(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUserPermissions({ uuid: user.uuid }));
  }, [dispatch, user.uuid]);

  useEffect(() => {
    if (user.uuid !== undefined) {
      dispatch(fetchUserProfile({ uuid: user.uuid }));
    }
  }, [dispatch, user]);

  setTitle('User Settings');

  const setValue = (key, value) => {
    dispatch(
      setUserProfile(
        {
          ...userProfile,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const setPasswordValue = (key, value) => {
    dispatch(
      setNewPassword(
        {
          ...newPassword,
          [key]: value,
          changed: true,
        },
      ),
    );
  };

  const handleReloadPermissions = () => {
    dispatch(setUser({
      ...user,
      permissions,
    }));
  };

  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Header
            name={`${user.first_name} ${user.last_name}`}
            role={user.role.name}
          />
        </Grid>
        <Grid item md={12}>
          <Grid
            container
            direction="row-reverse"
            spacing={2}
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              pb: '7px',
            }}
          >
            <Grid item>
              <Options>
                <MenuItem onClick={handleReloadPermissions}>{t('reload-permissions')}</MenuItem>
              </Options>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <BasicInfo
            setValue={setValue}
          />
        </Grid>
        <Grid item md={12}>
          <ChangePassword
            setValue={setPasswordValue}
          />
        </Grid>
        <Grid item md={12} style={{ display: 'none' }}>
          <Notifications />
        </Grid>
        <Grid item md={12}>
          <SavePanel />
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default UserSettings;
