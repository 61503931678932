// Components
import BaseLayout from 'layouts/components/BaseLayout';
import AccountSummary from 'layouts/accounts/components/Summary';
import Header from 'layouts/candidates/components/Header';

function AccountDetails() {
  return (
    <BaseLayout>
      <Header />
      <AccountSummary />
    </BaseLayout>
  );
}

export default AccountDetails;
