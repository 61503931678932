import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Reducer functions
import {
  fetchRecruitmentImmigration,
} from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { setTitle } from 'Util';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  recruitmentImmigration: state.reports.recruitmentImmigration,
});

function Immigration({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { recruitmentImmigration } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  setTitle('Recruitment Immigration Report');

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchRecruitmentImmigration({ statuses: parameters.statuses }));
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    if (recruitmentImmigration !== undefined) {
      setData(recruitmentImmigration);
    }
  }, [recruitmentImmigration]);

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  const columns = [
    {
      caption: t('name', { keyPrefix: 'common' }),
      field: 'candidate_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('first-name', { keyPrefix: 'common' }),
      field: 'first_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('last-name', { keyPrefix: 'common' }),
      field: 'last_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('status', { keyPrefix: 'common' }),
      field: 'candidate_status_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('hospital-name'),
      field: 'client_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('hospital-state'),
      field: 'client_state',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('recruiter'),
      field: 'recruiter',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('priority-date'),
      field: 'priority_date',
      calculateCellValue: (d) => handleDateFormat(d.priority_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('passport-expiration'),
      field: 'passport_date_expiration',
      calculateCellValue: (d) => handleDateFormat(d.passport_date_expiration),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test'),
      field: 'test_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test-expiration'),
      field: 'test_expiration',
      calculateCellValue: (d) => handleDateFormat(d.test_expiration),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('visa-screen-agency'),
      field: 'visa_screen_agency',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('visa-screen-application-date'),
      field: 'visa_screen_application_start',
      calculateCellValue: (d) => handleDateFormat(d.visa_screen_application_start),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('visa-screen-issued-date'),
      field: 'visa_screen_issue',
      calculateCellValue: (d) => handleDateFormat(d.visa_screen_issue),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-state'),
      field: 'license_state',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-number'),
      field: 'license_number',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-expiration-date'),
      field: 'license_expiration',
      calculateCellValue: (d) => handleDateFormat(d.license_expiration),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('expected-starting-date'),
      field: 'expected_starting_date',
      calculateCellValue: (d) => handleDateFormat(d.expected_starting_date),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('actual-starting-date'),
      field: 'actual_starting_date',
      calculateCellValue: (d) => handleDateFormat(d.actual_starting_date),
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={12}>
        <BaseTable name="RecruitmentImmigration" data={data} keyExpr="uuid" columns={columns} />
      </Grid>
    </Grid>
  );
}

Immigration.propTypes = {
  parameters: PropTypes.shape({
    statuses: PropTypes.arrayOf(PropTypes.number.isRequired),
  }).isRequired,
};

export default Immigration;
