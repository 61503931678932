import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import BaseParameters from 'layouts/reports/components/BaseParameters';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 1,
};

function BsrUsersParameters({
  parameters,
  handleParamsFunc,
  openParameters,
  closeParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState([]);
  const [params, setParams] = useState(parameters);

  useEffect(() => {
    if (parameters.tags !== undefined) {
      const sel = [];

      parameters.tags
        .forEach((tag) => sel.push({ value: tag, label: tag }));

      setSelectedTags(sel);
    }
  }, [dispatch, parameters]);

  const tags = [
    { value: 'A1', label: 'A1' },
    { value: 'A1.1', label: 'A1.1' },
    { value: 'A1.2', label: 'A1.2' },
    { value: 'a1.2', label: 'a1.2' },
    { value: 'A1.3', label: 'A1.3' },
    { value: 'A1.4', label: 'A1.4' },
    { value: 'A1.4FINALEXAM', label: 'A1.4FINALEXAM' },
    { value: 'A2', label: 'A2' },
    { value: 'A2P', label: 'A2P' },
    { value: 'A2.1', label: 'A2.1' },
    { value: 'a2.1', label: 'a2.1' },
    { value: 'A2.2', label: 'A2.2' },
    { value: 'A2.3', label: 'A2.3' },
    { value: 'A2.4', label: 'A2.4' },
    { value: 'A2.4FINALEXAM', label: 'A2.4FINALEXAM' },
    { value: 'B.2', label: 'B.2' },
    { value: 'B1', label: 'B1' },
    { value: 'B1EXAM', label: 'B1EXAM' },
    { value: 'B1.', label: 'B1.' },
    { value: 'B1.1', label: 'B1.1' },
    { value: 'b1.1', label: 'b1.1' },
    { value: 'B1.2', label: 'B1.2' },
    { value: 'B1.3', label: 'B1.3' },
    { value: 'b1.3', label: 'b1.3' },
    { value: 'B1.4', label: 'B1.4' },
    { value: 'B1.4FINALEXAM', label: 'B1.4FINALEXAM' },
    { value: 'B2', label: 'B2' },
    { value: 'b2', label: 'b2' },
    { value: 'B2EXAM', label: 'B2EXAM' },
    { value: 'B2-24', label: 'B2-24' },
    { value: 'B2-Final', label: 'B2-Final' },
    { value: 'B2.1', label: 'B2.1' },
    { value: 'b2.1', label: 'b2.1' },
    { value: 'B2.2', label: 'B2.2' },
    { value: 'B2.3', label: 'B2.3' },
    { value: 'B2.4', label: 'B2.4' },
    { value: 'B2.4FINALEXAM', label: 'B2.4FINALEXAM' },
    { value: 'B2C', label: 'B2C' },
    { value: 'B2c', label: 'B2c' },
    { value: 'BRASIL', label: 'BRASIL' },
    { value: 'BSR', label: 'BSR' },
    { value: 'C1', label: 'C1' },
    { value: 'C1.1', label: 'C1.1' },
    { value: 'C1.2', label: 'C1.2' },
    { value: 'C2.1', label: 'C2.1' },
    { value: 'EXAMEN', label: 'EXAMEN' },
    { value: 'EXAMENB1', label: 'EXAMENB1' },
    { value: 'ExamendeNivel', label: 'ExamendeNivel' },
    { value: 'IELTS', label: 'IELTS' },
    { value: 'IETLS', label: 'IETLS' },
    { value: 'NASH', label: 'NASH' },
    { value: 'NASHEMPLOYEE', label: 'NASHEMPLOYEE' },
    { value: 'NASHFamily', label: 'NASHFamily' },
    { value: 'NI', label: 'NI' },
    { value: 'NI-A', label: 'NI-A' },
    { value: 'NI-BP', label: 'NI-BP' },
    { value: 'NI-BS', label: 'NI-BS' },
    { value: 'NursingEnglish', label: 'NursingEnglish' },
    { value: 'ProgressExam1B1', label: 'ProgressExam1B1' },
    { value: 'QUICKASSESSMENT', label: 'QUICKASSESSMENT' },
    { value: 'TOEFL', label: 'TOEFL' },
    { value: 'TOEFLICIOUS', label: 'TOEFLICIOUS' },
  ];

  const handleTags = (newItems) => {
    setSelectedTags(newItems);
    setParams({
      tags: newItems.map((s) => s.value),
    });
  };

  return (
    <BaseParameters
      parameters={params}
      style={style}
      openParameters={openParameters}
      closeParamsFunc={closeParamsFunc}
      handleParamsFunc={(p) => handleParamsFunc(p)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {t('statuses')}
              </SuiTypography>
            </SuiBox>
            <Autocomplete
              multiple
              id="tags"
              options={tags}
              getOptionLabel={(option) => option.label}
              value={selectedTags}
              filterSelectedOptions
              renderInput={(tag) => (
                <TextField
                  {...tag}
                  variant="outlined"
                  placeholder={t('tags')}
                />
              )}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  handleTags(newValue);
                } else if (newValue && newValue.inputValue) {
                  handleTags(newValue.inputValue);
                } else {
                  handleTags(newValue);
                }
              }}
            />
          </SuiBox>
        </Grid>
      </Grid>
    </BaseParameters>
  );
}

BsrUsersParameters.propTypes = {
  parameters: PropTypes.shape({
    tags: PropTypes.string,
  }).isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
};
export default BsrUsersParameters;
