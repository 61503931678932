import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';

// Functions
import { checkPermission, setTitle } from 'Util';

// Components
import PaymentPlans from './components/PaymentPlans';
import DueInvoices from './components/DueInvoices';
import ReportsBaseLayout from '../components/BaseLayout';
import DueInvoicesParameters from './components/Parameters/DueInvoicesParameters';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function FinancialReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);

  const [selected, setSelected] = useState({ value: '' });
  const [openParams, setOpenParams] = useState(false);
  const [parameters, setParameters] = useState(null);

  setTitle('Financial Reports');

  const openParameters = () => {
    setOpenParams(true);
  };

  const closeParameters = () => {
    setOpenParams(false);
  };

  const handleParameters = (params) => {
    setParameters(params);
  };

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R101', currentUser)) {
      r.push({
        value: 'payment-plans',
        label: t('payment-plans'),
        parameters: false,
      });
    }

    if (checkPermission('R102', currentUser)) {
      const today = new Date();
      r.push({
        value: 'due-invoices',
        label: t('due-invoices'),
        parameters: true,
        defaultParameters: {
          date: `${today.getFullYear()}-${(today.getMonth() + 1)
            .toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`,
        },
      });
    }

    return r;
  }, [currentUser, t]);

  return (
    <ReportsBaseLayout
      reports={reports}
      selectReport={setSelected}
      showParameters={selected.parameters}
      openParamsFunc={openParameters}
    >
      {selected.value === 'payment-plans' && <PaymentPlans />}
      {selected.value === 'due-invoices' && (
        <div>
          <DueInvoices parameters={parameters !== null ? parameters : selected.defaultParameters} />
          <DueInvoicesParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
    </ReportsBaseLayout>
  );
}

export default FinancialReports;
