import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import BaseParameters from 'layouts/reports/components/BaseParameters';
import FormDateRangePicker from 'layouts/components/FormDateRangePicker';

// Functions
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 1,
};

function CommitmentMeetingsParameters({
  parameters,
  handleParamsFunc,
  openParameters,
  closeParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);
  const [params, setParams] = useState(parameters);

  useEffect(() => {
    if (parameters !== undefined) {
      let fd = dayjs().subtract(1, 'month');
      let td = dayjs();

      const { from, to } = parameters;
      if (from !== undefined && from !== null) {
        fd = dayjs(from);
      }
      if (to !== undefined && to !== null) {
        td = dayjs(to);
      }

      setSelectedDate([fd, td]);
    }
  }, [dispatch, parameters]);

  const handleDate = (e) => {
    setSelectedDate(e);
    setParams({
      from: e[0] !== null ? e[0].format('YYYY-MM-DD') : null,
      to: e[1] !== null ? e[1].format('YYYY-MM-DD') : null,
    });
  };

  return (
    <BaseParameters
      parameters={params}
      style={style}
      openParameters={openParameters}
      closeParamsFunc={closeParamsFunc}
      handleParamsFunc={(p) => handleParamsFunc(p)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item>
          <FormDateRangePicker
            label={t('date')}
            localeText={{
              start: '',
              end: '',
            }}
            value={selectedDate}
            onChange={handleDate}
          />
        </Grid>
        <Grid item md={8} />
      </Grid>
    </BaseParameters>
  );
}

CommitmentMeetingsParameters.propTypes = {
  parameters: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
};

export default CommitmentMeetingsParameters;
