import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import AgreementService from 'services/agreement.service';

// Functions
import {
  fulfilledCreatedReducer,
  fulfilledDeletedReducer,
  fulfilledReducer,
  fulfilledSavedReducer,
  pendingReducer,
  rejectionReducer,
} from 'Util';

export const fetchAgreements = createAsyncThunk(
  'agreement/fetchAgreements',
  async (payload, { rejectWithValue }) => {
    try {
      return await AgreementService.getAgreements();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAgreement = createAsyncThunk(
  'agreement/fetchAgreement',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AgreementService.getAgreement(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAgreementVersion = createAsyncThunk(
  'agreement/fetchAgreementVersion',
  async (payload, { rejectWithValue }) => {
    const { id, version } = payload;
    try {
      return await AgreementService.getAgreementVersion(id, version);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const createAgreement = createAsyncThunk(
  'agreement/createAgreement',
  async (payload, { rejectWithValue }) => {
    const {
      name,
      agreementTypeId,
      language,
      expires,
      expiration,
      agreementTagList,
      agreementVersions,
    } = payload;
    try {
      return await AgreementService
        .createAgreement(
          name,
          agreementTypeId,
          language,
          expires,
          expiration,
          agreementTagList,
          agreementVersions,
        );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const updateAgreement = createAsyncThunk(
  'agreement/updateAgreement',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      name,
      agreementTypeId,
      language,
      expires,
      expiration,
      agreementTagList,
      agreementVersions,
    } = payload;
    try {
      return await AgreementService.updateAgreement(
        id,
        name,
        agreementTypeId,
        language,
        expires,
        expiration,
        agreementTagList,
        agreementVersions,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const deleteAgreement = createAsyncThunk(
  'agreement/deleteAgreement',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AgreementService.deleteAgreement(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

const generateDropboxProperties = ({ payload }) => payload.map((item) => ({
  ...item,
  value: item.id,
  label: item.name,
}));

/* eslint-disable no-param-reassign */
export const agreementSlice = createSlice({
  name: 'agreement',
  initialState: {
    agreementInfo: {
      name: '',
      content: '',
      agreement_type_id: undefined,
      language: undefined,
    },
    agreements: [],
    message: '',
    errors: [],
    editing: false,
    created: false,
    saved: false,
    deleted: false,
    failed: false,
    succeeded: false,
  },
  reducers: {
    clearAgreement: (state) => {
      state.message = '';
      state.errors = [];
      state.editing = false;
      state.succeeded = false;
      state.created = false;
      state.saved = false;
      state.deleted = false;
      state.failed = false;
      state.agreementInfo = {
        name: '',
        content: '',
        agreement_type_id: undefined,
        language: undefined,
        new: true,
        version: 1,
        expiration: '1-Y',
        agreement_versions: [{
          id: null,
          content: '',
          current: true,
          version: 1,
        }],
      };
    },
    setAgreementEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setSucceeded: (state, action) => {
      state.succeeded = action.payload;
    },
    setFailed: (state, action) => {
      state.failed = action.payload;
    },
    setAgreementInfo: (state, action) => {
      state.agreementInfo = action.payload;
    },
  },
  extraReducers: (builder) => builder.addMatcher(
    (action) => action.type.endsWith('/rejected'),
    rejectionReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/pending'),
    pendingReducer,
  ).addMatcher(
    (action) => action.type.endsWith('/fulfilled'),
    (state, action) => {
      const performedAction = action.type.split('/');
      if (performedAction[0] === 'agreement') {
        switch (performedAction[1]) {
          case 'fetchAgreements':
            fulfilledReducer(state, action);
            state.agreements = generateDropboxProperties(action);
            break;
          case 'fetchAgreement':
          case 'fetchAgreementVersion':
            fulfilledReducer(state, action);
            state.agreementInfo = action.payload;
            break;
          case 'createAgreement':
            fulfilledCreatedReducer(state, action);
            break;
          case 'updateAgreement':
            fulfilledSavedReducer(state, action);
            break;
          case 'deleteAgreement':
            fulfilledDeletedReducer(state, action);
            break;
          default:
            fulfilledReducer(state, action);
            state.message = action.payload;
            break;
        }
      }
    },
  ),
});

/* eslint-disable no-param-reassign */
export const {
  clearAgreement,
  setAgreementEditing,
  setCreated,
  setSaved,
  setDeleted,
  setFailed,
  setSucceeded,
  setAgreementInfo,
} = agreementSlice.actions;

export default agreementSlice.reducer;
