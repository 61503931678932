import Service from './service';

class ClientService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getClients() {
    const response = await this.api
      .get(
        'clients',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getClient(id) {
    const response = await this.api
      .get(
        `clients/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createClient(
    name,
    website,
    address,
    stateId,
    cityId,
    attorneyId,
    recruitmentList,
    contracts,
    prevailingWageDate,
    prevailingWageReminder,
    prevailingWageExpected,
  ) {
    const data = {
      client: {
        name,
        website,
        address,
        state_id: stateId,
        city_id: cityId,
        attorney_id: attorneyId,
        recruitment_list: recruitmentList,
        client_contracts_attributes: contracts,
        prevailing_wage_date: prevailingWageDate,
        prevailing_wage_reminder: prevailingWageReminder,
        prevailing_wage_expected: prevailingWageExpected,
      },
    };

    const response = await this.api
      .post(
        'clients',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateClient(
    id,
    name,
    website,
    address,
    stateId,
    cityId,
    attorneyId,
    recruitmentList,
    contacts,
    contracts,
    prevailingWageDate,
    prevailingWageReminder,
    prevailingWageExpected,
  ) {
    const data = {
      client: {
        name,
        website,
        address,
        state_id: stateId,
        city_id: cityId,
        attorney_id: attorneyId,
        recruitment_list: recruitmentList,
        client_contacts_attributes: contacts,
        client_contracts_attributes: contracts,
        prevailing_wage_date: prevailingWageDate,
        prevailing_wage_reminder: prevailingWageReminder,
        prevailing_wage_expected: prevailingWageExpected,
      },
    };

    const response = await this.api
      .put(
        `clients/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteClient(id) {
    const response = await this.api
      .delete(
        `clients/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getClientContacts(id) {
    const response = await this.api
      .get(
        this.localizeURL(`clients/${id}/contacts`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createClientContact(
    clientId,
    name,
    position,
    email,
    phone,
  ) {
    const data = {
      contact: {
        name,
        position,
        email,
        phone,
      },
    };

    const response = await this.api
      .post(
        `clients/${clientId}/contacts`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateClientContact(
    id,
    clientId,
    name,
    position,
    email,
    phone,
  ) {
    const data = {
      contact: {
        name,
        position,
        email,
        phone,
      },
    };

    const response = await this.api
      .put(
        `clients/${clientId}/contacts/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteClientContact(clientId, id) {
    const response = await this.api
      .delete(
        `clients/${clientId}/contacts/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateClientContractDocument(
    id,
    clientId,
    files,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', files);

    const response = await this.api
      .put(
        this.localizeURL(`clients/${clientId}/contracts/${id}/upload-files`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }
}

export default new ClientService();
