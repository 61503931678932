import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import { ButtonGroup } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';
import SuiButton from 'components/SuiButton';

import imgAvatar from 'assets/images/default-avatar.svg';

function AccountNavbarCard({
  image, name, email, settingsFunc, signOutFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const settingsHandler = (e) => settingsFunc(e);
  const signOutHandler = (e) => signOutFunc(e);
  return (
    <SuiBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <SuiAvatar
        src={image}
        alt={name}
        size="xxl"
        shadow="md"
        variant="circular"
      />
      <SuiBox p={3}>
        <SuiTypography variant="h4">{name}</SuiTypography>
        <SuiTypography variant="h6" color="text">
          {email}
        </SuiTypography>
      </SuiBox>
      <SuiBox>
        <ButtonGroup
          orientation="vertical"
          aria-label="Vertical button group"
          variant="text"
        >
          <SuiButton variant="text" color="info" size="large" onClick={settingsHandler} sx={{ height: '50px' }}>
            {t('settings')}
          </SuiButton>
          <SuiButton variant="text" color="info" size="large" onClick={signOutHandler} sx={{ height: '50px' }}>
            {t('sign-out')}
          </SuiButton>
        </ButtonGroup>
      </SuiBox>
    </SuiBox>
  );
}

// Setting admin props for the AccountNavbarCard
AccountNavbarCard.defaultProps = {
  image: imgAvatar,
};

// Typechecking props for the AccountNavbarCard
AccountNavbarCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  settingsFunc: PropTypes.func.isRequired,
  signOutFunc: PropTypes.func.isRequired,
};

export default AccountNavbarCard;
