import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Reducer Functions
import { fetchCandidatesProgress } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components
import PivotGrid, { FieldChooser, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
// eslint-disable-next-line object-curly-newline
import Chart, { AdaptiveLayout, CommonSeriesSettings, Export, Size, Tooltip } from 'devextreme-react/chart';
import { exportPivotGrid } from 'devextreme/excel_exporter';

// Other components
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

// Functions
import { setTitle } from 'Util';
import useWindowDimensions from 'layouts/components/Hooks/WindowDimensions';

const selector = (state) => ({
  candidatesProgress: state.reports.candidatesProgress,
});

function Progress({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    candidatesProgress,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  setTitle('Candidates Progress Report');

  const chart = useRef(null);
  const pivotGrid = useRef(null);

  const [parentHeight, setParentHeight] = useState(500);
  const [reportData, setReportData] = useState([]);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setParentHeight(height - 150);
  }, [height]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('advisor'),
          dataField: 'advisor_name',
          width: 200,
          area: 'row',
        },
        {
          caption: t('candidate'),
          dataField: 'candidate_name',
          width: 150,
          area: 'row',
        },
        {
          caption: t('section'),
          dataField: 'process',
          area: 'column',
          expanded: true,
        },
        {
          caption: t('sub-section'),
          dataField: 'value',
          area: 'column',
        },
        {
          dataField: 'candidate_uuid',
          area: 'column',
          visible: false,
        },
        {
          dataField: 'advisor_uuid',
          area: 'column',
          visible: false,
        },
        {
          caption: t('total'),
          dataField: 'uuid',
          summaryType: 'count',
          dataType: 'number',
          area: 'data',
        },
      ],
      store: candidatesProgress,
    });

    setReportData(dataSource);

    pivotGrid.current.instance.bindChart(chart.current.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });
  }, [candidatesProgress, t]);

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchCandidatesProgress({ statuses: parameters.statuses }));
    }
  }, [parameters, dispatch]);

  const customizeTooltip = (args) => {
    const valueText = args.originalValue;
    return {
      html: `${args.seriesName} | Total ${valueText}`,
    };
  };

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Overview');

    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CandidatesProgress.xlsx');
      });
    });
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item xs={12} md={1} />
      <Grid item xs={12} md={10}>
        <Card>
          <Grid containe m={3}>
            <Grid item md={12}>
              <Chart ref={chart}>
                <Size height={200} />
                <CommonSeriesSettings type="stackedbar" />
                <AdaptiveLayout width={450} />
                <Tooltip enabled customizeTooltip={customizeTooltip} />
              </Chart>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={1} />
      <Grid item md={12}>
        <Card>
          <Grid item m={3} md={12}>
            <PivotGrid
              id="candidates-overview"
              dataSource={reportData}
              allowSortingBySummary
              allowFiltering
              showBorders={false}
              showColumnTotals={false}
              showColumnGrandTotals={false}
              showRowTotals
              showRowGrandTotals
              ref={pivotGrid}
              onExporting={onExporting}
              height={parentHeight}
            >
              <FieldChooser enabled height={400} />
              <Export enabled />
              <Scrolling mode="virtual" />
            </PivotGrid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

Progress.propTypes = {
  parameters: PropTypes.instanceOf({
    statuses: PropTypes.arrayOf(PropTypes.number.isRequired),
  }).isRequired,
};
export default Progress;
