import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Reducer Functions
import { fetchCourses } from 'reducers/bsrSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import BaseParameters from 'layouts/reports/components/BaseParameters';
import FormSelect from 'layouts/components/FormSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 1,
};

const selector = (state) => ({
  courses: state.bsr.courses,
});

function BsrCoursesParameters({
  parameters,
  handleParamsFunc,
  openParameters,
  closeParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const {
    courses,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [bsrCourse, setBsrCourse] = useState([]);
  const [coursesPage, setCoursesPage] = useState(1);
  const [params, setParams] = useState(parameters);

  useEffect(() => {
    if (courses !== undefined && courses.meta !== undefined) {
      if (courses.meta.page >= coursesPage) {
        setBsrCourse(bsrCourse.concat(courses.data));
        setCoursesPage(courses.meta.page);
      }

      if (courses.meta.page < courses.meta.totalPages) {
        dispatch(fetchCourses({ page: courses.meta.page + 1 }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, courses]);

  useEffect(() => {
    setBsrCourse([]);
    setCoursesPage(1);
    dispatch(fetchCourses({ page: 1 }));
  }, [dispatch, parameters]);

  const handleSelect = (e) => {
    setParams({ course: e.value });
  };

  const courseSelection = bsrCourse.map((c) => ({ value: c.id, label: c.title }));

  return (
    <BaseParameters
      parameters={params}
      style={style}
      openParameters={openParameters}
      closeParamsFunc={closeParamsFunc}
      handleParamsFunc={(p) => handleParamsFunc(p)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ height: '100px' }}
      >
        <Grid item md={12}>
          <FormSelect
            label={t('course')}
            // value={selected}
            options={courseSelection}
            onChange={handleSelect}
            sx={{ overflow: 'visible' }}
          />
        </Grid>
      </Grid>
    </BaseParameters>
  );
}

BsrCoursesParameters.propTypes = {
  parameters: PropTypes.shape({
    course: PropTypes.string,
  }).isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
};

export default BsrCoursesParameters;
