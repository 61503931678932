import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchRecruitmentPotentials } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { setTitle } from 'Util';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  recruitmentPotentials: state.reports.recruitmentPotentials,
});

function Potentials({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { recruitmentPotentials } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  setTitle('Recruitment Potentials Report');

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchRecruitmentPotentials({ statuses: parameters.statuses }));
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    if (recruitmentPotentials !== undefined) {
      setData(recruitmentPotentials);
    }
  }, [recruitmentPotentials]);

  const handleDateFormat = (d) => {
    if (d !== null) {
      return Date.parse(d);
    }
    return null;
  };

  const columns = [
    {
      caption: t('name', { keyPrefix: 'common' }),
      field: 'full_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('first-name', { keyPrefix: 'common' }),
      field: 'first_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('last-name', { keyPrefix: 'common' }),
      field: 'last_name',
      visible: false,
      allowFixing: true,
    },
    {
      caption: t('status', { keyPrefix: 'common' }),
      field: 'candidate_status',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('country'),
      field: 'country_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-state'),
      field: 'license_state',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-number'),
      field: 'license_number',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-issued'),
      field: 'license_issue',
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('license-expiration'),
      field: 'license_expiration',
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test'),
      field: 'test_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test-date'),
      field: 'test_schedule',
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('english-test-result'),
      field: 'test_result',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('right-to-represent-signed'),
      field: 'r2r_signed',
      calculateCellValue: (d) => (d.r2r_signed ? t('yes') : t('no')),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('right-to-represent-signed-date'),
      field: 'r2r_signed_on',
      calculateCellValue: (d) => handleDateFormat(d.r2r_signed_on),
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('right-to-represent-expiration-date'),
      field: 'r2r_expires_on',
      calculateCellValue: (d) => handleDateFormat(d.r2r_expires_on),
      dataType: 'date',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('clinical-experience'),
      field: 'clinical_experiences',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('currently-employed'),
      field: 'currently_employed',
      calculateCellValue: (d) => (d.currently_employed ? t('yes') : t('no')),
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('years-experience'),
      field: 'years_experience',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('degree-type'),
      field: 'degree_type',
      visible: true,
      allowFixing: true,
    },
  ];

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={12}>
        <BaseTable name="RecruitmentPotentials" data={data} keyExpr="uuid" columns={columns} />
      </Grid>
    </Grid>
  );
}

Potentials.propTypes = {
  parameters: PropTypes.shape({
    statuses: PropTypes.arrayOf(PropTypes.number.isRequired),
  }).isRequired,
};

export default Potentials;
