import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchCandidateRecruitmentSummary, fetchCandidateSummary } from 'reducers/reportsSlice';
import { setGoToView, setGoToViewSubView } from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Functions
import { findSelectValue } from 'Util';

const selector = (state) => ({
  cs: state.reports.candidateSummary,
});

function CandidateSummary({ uuid, view }) {
  const { t } = useTranslation('translation', { keyPrefix: 'widgets' });
  const { cs } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (view === 'recruitment') {
      dispatch(fetchCandidateRecruitmentSummary({ uuid }));
    } else if (view === 'coaching') {
      dispatch(fetchCandidateSummary({ uuid }));
    } else {
      dispatch(fetchCandidateSummary({ uuid }));
    }
  }, [dispatch, uuid, view]);

  const meetingTypes = useMemo(() => [
    {
      value: 1,
      label: t('other-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 2,
      label: t('commitment-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 3,
      label: t('first-informational-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 4,
      label: t('second-informational-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 5,
      label: t('background-check-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 6,
      label: t('pearson-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 7,
      label: t('nclex-meeting', { keyPrefix: 'candidates.meetings' }),
    },
    {
      value: 8,
      label: t('recruitment-meeting', { keyPrefix: 'candidates.meetings' }),
    },
  ], [t]);

  const formatDate = (d) => {
    if (d !== null && d !== undefined) {
      return Date.parse(d).toLocaleDateString();
    }
    return ('');
  };

  const formatLongDate = (d) => {
    if (d !== null && d !== undefined) {
      return Date.parse(d).toLocaleString();
    }
    return ('');
  };

  const formatBoolean = (b) => {
    if (b) {
      return t('yes');
    }
    return t('no');
  };

  const validate = (v) => v !== undefined && v !== null;

  const handleClick = (e, section) => {
    e.preventDefault();
    if (section === 'profile') {
      dispatch(setGoToViewSubView({
        view: 'profile',
        subview: 'basic-info',
      }));
    } else if (section === 'language') {
      dispatch(setGoToViewSubView({
        view: 'profile',
        subview: 'language',
      }));
    } else if (section === 'certification') {
      dispatch(setGoToViewSubView({
        view: 'certifications',
        subview: 'credentials-evaluation',
      }));
    } else if (section === 'bon') {
      dispatch(setGoToViewSubView({
        view: 'certifications',
        subview: 'nursing-board',
      }));
    } else if (section === 'fingerprints') {
      dispatch(setGoToViewSubView({
        view: 'certifications',
        subview: 'fingerprint',
      }));
    } else if (section === 'nclex') {
      dispatch(setGoToViewSubView({
        view: 'certifications',
        subview: 'nclex',
      }));
    } else if (section === 'meetings') {
      dispatch(setGoToViewSubView({
        view: 'communications',
        subview: 'meetings',
      }));
    } else if (section === 'immigration') {
      dispatch(setGoToViewSubView({
        view: 'profile',
        subview: 'immigration',
      }));
    } else if (section === 'degree-eval') {
      dispatch(setGoToViewSubView({
        view: 'certifications',
        subview: 'credentials-evaluation',
      }));
    } else if (section === 'license') {
      dispatch(setGoToViewSubView({
        view: 'certifications',
        subview: 'credentials',
      }));
    } else if (section === 'clinical-exp') {
      dispatch(setGoToViewSubView({
        view: 'profile',
        subview: 'experience',
      }));
    } else if (section === 'client') {
      dispatch(setGoToViewSubView({
        view: 'recruitment',
        subview: 'job-applications',
      }));
    } else if (section === 'notes') {
      dispatch(setGoToView('notes'));
    } else if (section === 'agreements') {
      dispatch(setGoToViewSubView({
        view: 'profile',
        subview: 'agreements',
      }));
    }
  };

  useEffect(() => {
    const cleanData = (v) => (validate(v) ? v : '');

    const cleanMeetingType = (type) => {
      if (validate(type)) {
        return findSelectValue(meetingTypes, type).label;
      }
      return '';
    };

    if (cs !== undefined) {
      if (view === 'recruitment') {
        setItems([
          {
            valid: validate(cs.country),
            title: t('country'),
            detail: cleanData(cs.country),
            section: 'profile',
          },
          {
            valid: validate(cs.residence_country),
            title: t('residence-country'),
            detail: cleanData(cs.residence_country),
            section: 'profile',
          },
          {
            valid: validate(cs.language),
            title: t('native-language'),
            detail: cleanData(cs.language),
            section: 'profile',
          },
          {
            valid: validate(cs.asylum_approved),
            title: t('immigration'),
            detail: `${t('asylum')}: ${formatBoolean(cleanData(cs.asylum_approved))} - ${t('issues')}: ${formatBoolean(cleanData(cs.immigration_issues))}`,
            section: 'immigration',
          },
          {
            valid: validate(cs.r2r_signed_on),
            title: t('signed-r2r'),
            detail: `${formatDate(cs.r2r_signed_on)}`,
            section: 'agreements',
          },
          {
            valid: validate(cs.degree_type),
            title: t('degree-type'),
            detail: `${cleanData(cs.degree_type)}`,
            section: 'degree-eval',
          },
          {
            valid: validate(cs.license_expiration),
            title: t('nursing-license'),
            detail: `${t('expiration')}: ${formatDate(cs.license_expiration)} ${t('state')}: ${cleanData(cs.license_state)}`,
            section: 'license',
          },
          {
            valid: validate(cs.test_name),
            title: t('english-exam'),
            detail: cs.test_name !== null ? `${cs.test_name}, ${formatDate(cs.test_schedule)}, ${cleanData(cs.test_result)}` : '',
            section: 'language',
          },
          {
            valid: validate(cs.clinical_experiences),
            title: t('clinical-experience'),
            detail: cleanData(cs.clinical_experiences),
            section: 'clinical-exp',
          },
          {
            valid: validate(cs.client_name),
            title: t('client'),
            detail: `${t('client')}: ${cleanData(cs.client_name)} ${t('state')}: ${cleanData(cs.client_state)}`,
            section: 'client',
          },
          {
            valid: validate(cs.interview_date),
            title: t('interview-date'),
            detail: formatDate(cs.interview_date),
            section: 'client',
          },
          {
            valid: validate(cs.offer_received_date),
            title: t('offer-date'),
            detail: formatDate(cs.offer_received_date),
            section: 'client',
          },
          {
            valid: validate(cs.priority_date),
            title: t('priority-date'),
            detail: formatDate(cs.priority_date),
            section: 'immigration',
          },
          {
            valid: validate(cs.client_name),
            title: t('visa-screen'),
            detail: validate(cs.client_name) ? `${t('agency')}: ${cleanData(cs.visa_screen_agency)} ${t('issued')}: ${formatDate(cs.visa_screen_issue)}` : '',
            section: 'immigration',
          },
          {
            valid: validate(cs.meeting_starts),
            title: t('meetings'),
            detail: validate(cs.meeting_starts) ? `${formatLongDate(cs.meeting_starts)}, ${cleanMeetingType(cs.meeting_type)}` : '',
            section: 'meetings',
          },
        ]);
      } else {
        setItems([
          {
            valid: validate(cs.country),
            title: t('country'),
            detail: cleanData(cs.country),
            section: 'profile',
          },
          {
            valid: validate(cs.level),
            title: t('english'),
            detail: validate(cs.level)
              ? `${cleanData(cs.school_name)}, ${cleanData(cs.level)}, ${formatDate(cs.english_evaluation_date)}`
              : '',
            section: 'language',
          },
          {
            valid: validate(cs.credential_evaluator_name),
            title: t('certification'),
            detail: validate(cs.credential_evaluator_name)
              ? `${cs.credential_evaluator_name}, ${formatDate(cs.credential_evaluation_started_date)}`
              : '',
            section: 'certification',
          },
          {
            valid: validate(cs.ces_issue_date),
            title: t('ces'),
            detail: validate(cs.ces_issue_date)
              ? `${formatDate(cs.ces_issue_date)}`
              : '',
            section: 'certification',
          },
          {
            valid: validate(cs.bon_date),
            title: t('bon'),
            detail: validate(cs.bon_date)
              ? `${cleanData(cs.bon_state)}, ${formatDate(cs.bon_date)}`
              : '',
            section: 'bon',
          },
          {
            valid: validate(cs.fp_date),
            title: t('fingerprints'),
            detail: `${formatDate(cs.fp_date)}`,
            section: 'fingerprints',
          },
          {
            valid: validate(cs.att_received),
            title: t('att'),
            detail: `${formatDate(cs.att_received)}`,
            section: 'nclex',
          },
          {
            valid: validate(cs.nclex_scheduled),
            title: t('nclex'),
            detail: validate(cs.nclex_scheduled)
              ? `${formatDate(cs.nclex_scheduled)}, ${cleanData(cs.nclex_result)}`
              : '',
            section: 'nclex',
          },
          {
            valid: validate(cs.archer_registration_date),
            title: t('archer'),
            detail: `${formatDate(cs.archer_registration_date)}`,
            section: 'nclex',
          },
          {
            valid: validate(cs.saunders_registration_date),
            title: t('saunders'),
            detail: `${formatDate(cs.saunders_registration_date)}`,
            section: 'nclex',
          },
          {
            valid: validate(cs.meeting_starts),
            title: t('meetings'),
            detail: validate(cs.meeting_starts) ? `${formatLongDate(cs.meeting_starts)}, ${cleanMeetingType(cs.meeting_type)}` : '',
            section: 'meetings',
          },
          {
            valid: validate(cs.meeting_starts),
            title: t('notes'),
            detail: validate(cs.meeting_starts) ? `${formatLongDate(cs.note_date)}, ${cleanData(cs.note_title)}` : '',
            section: 'notes',
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cs, meetingTypes, t, view]);

  const renderItems = items.map((i, k) => (
    <ListItem key={`item-${k}`} onClick={(e) => handleClick(e, i.section)}>
      <ListItemIcon>
        {i.valid && <CheckCircleIcon color="success" />}
        {!i.valid && <DangerousIcon color="error" />}
      </ListItemIcon>
      <ListItemText primary={i.title} secondary={i.detail} />
    </ListItem>
  ));

  return (
    <Card id="candidate-summary">
      <SuiBox pt={2} px={2} lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          {t('summary')}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {renderItems}
        </List>
      </SuiBox>
    </Card>
  );
}

CandidateSummary.defaultProps = {
  view: 'default',
};

CandidateSummary.propTypes = {
  uuid: PropTypes.string.isRequired,
  view: PropTypes.string,
};
export default CandidateSummary;
