import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';

import SuiBox from 'components/SuiBox';

import FormField from 'layouts/components/FormField';
import FormPasswordInput from 'layouts/components/FormPasswordInput';

import { defaultValue } from 'Util';

function DefaultPearsonCard(
  {
    i,
    r,
    setValue,
    removeFunc,
    hasPaymentDate,
    permissions,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nclex' });

  const [expanded, setExpanded] = useState(true);
  const [pearsonMenu, setPearsonMenu] = useState(null);
  const [title, setTitle] = useState(`${t('pearson-vue')} ${i + 1}`);

  const openPearsonMenu = (event) => setPearsonMenu(event.currentTarget);
  const closePearsonMenu = () => setPearsonMenu(null);

  useEffect(() => {
    if (r.id !== undefined && r.id !== null) {
      setTitle(`${t('pearson-vue')} ${i + 1}`);
    }
  }, [i, r, t]);

  const handleExpand = () => {
    closePearsonMenu();
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        action={(
          <IconButton onClick={openPearsonMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={title}
      />
      <Menu
        anchorEl={pearsonMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(pearsonMenu)}
        onClose={closePearsonMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <SuiBox component="form" pb={1} px={2}>
            <Grid container spacing={3}>
              {permissions.loginInfo && (
                <>
                  <Grid item md={3}>
                    <FormField
                      label={t('user')}
                      error={r.user ? false : !r.user || r.user === ''}
                      onChange={(event) => {
                        setValue('user', event.target.value, i);
                      }}
                      value={defaultValue(r.user)}
                    />
                  </Grid>
                  <Grid item md={3} pr={1}>
                    <FormPasswordInput
                      label={t('password')}
                      inputProps={{ autoComplete: '' }}
                      error={r.password ? false : !r.password || r.password === ''}
                      onChange={(event) => {
                        setValue('password', event.target.value, i);
                      }}
                      value={defaultValue(r.password)}
                    />
                  </Grid>
                </>
              )}
              {permissions.dates && (
                <Grid item md={hasPaymentDate ? 2 : 3} pr={1}>
                  <FormField
                    label={t('registration-date')}
                    type="date"
                    error={r.registration_date ? false : !r.registration_date || r.registration_date === ''}
                    onChange={(event) => {
                      setValue('registration_date', event.target.value, i);
                    }}
                    value={defaultValue(r.registration_date)}
                  />
                </Grid>
              )}
              {permissions.ncsbn && (
                <Grid item md={hasPaymentDate ? 2 : 3} pr={1}>
                  <FormField
                    label={t('ncsbn-id')}
                    error={r.ncsbn_id ? false : !r.ncsbn_id || r.ncsbn_id === ''}
                    onChange={(event) => {
                      setValue('ncsbn_id', event.target.value, i);
                    }}
                    value={defaultValue(r.ncsbn_id)}
                  />
                </Grid>
              )}
              {(permissions.dates && hasPaymentDate) && (
                <Grid item md={2} pr={1}>
                  <FormField
                    label={t('payment-date')}
                    type="date"
                    error={r.payment_date ? false : !r.payment_date || r.payment_date === ''}
                    onChange={(event) => {
                      setValue('payment_date', event.target.value, i);
                    }}
                    value={defaultValue(r.payment_date)}
                  />
                </Grid>
              )}
            </Grid>
          </SuiBox>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DefaultPearsonCard.defaultProps = {
  hasPaymentDate: false,
  permissions: {
    loginInfo: false,
    dates: false,
    ncsbn: false,
  },
};

DefaultPearsonCard.propTypes = {
  i: PropTypes.number.isRequired,
  r: PropTypes.shape(
    {
      id: PropTypes.number,
      user: PropTypes.string,
      password: PropTypes.string,
      registration_date: PropTypes.string,
      ncsbn_id: PropTypes.string,
      payment_date: PropTypes.string,
    },
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  hasPaymentDate: PropTypes.bool,
  permissions: PropTypes.shape({
    loginInfo: PropTypes.bool,
    dates: PropTypes.bool,
    ncsbn: PropTypes.bool,
  }),
};

export default DefaultPearsonCard;
