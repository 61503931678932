import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Reducer functions
import { fetchCommitmentMeetings } from 'reducers/reportsSlice';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// DevExtreme components
import PivotGrid, { FieldChooser, Export, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

// DevExtreme components
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

// Components
import BaseTable from 'layouts/reports/components/BaseTable';

const selector = (state) => ({
  commitmentMeetings: state.reports.commitmentMeetings,
});

function CommitmentMeetings({ parameters }) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { commitmentMeetings } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (parameters !== undefined) {
      dispatch(fetchCommitmentMeetings({
        from: parameters.from,
        to: parameters.to,
      }));
    }
  }, [parameters, dispatch]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('advisor'),
          dataField: 'advisor_name',
          width: 200,
          area: 'row',
          expanded: true,
        },
        {
          caption: t('meeting-outcome'),
          dataField: 'outcome',
          width: 200,
          area: 'row',
        },
        {
          caption: t('candidate'),
          dataField: 'candidate_name',
          width: 200,
          area: 'row',
        },
        {
          caption: t('meeting-date'),
          dataField: 'meeting_starts',
          dataType: 'date',
          area: 'column',
        },
        {
          groupName: 'meeting_starts',
          groupInterval: 'year',
          expanded: true,
        },
        {
          groupName: 'meeting_starts',
          groupInterval: 'quarter',
          expanded: true,
        },
        {
          groupName: 'meeting_starts',
          groupInterval: 'month',
          expanded: true,
        },
        {
          groupName: 'meeting_starts',
          groupInterval: 'day',
          expanded: true,
        },
        {
          dataField: 'uuid',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'status_id',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'status_date_change',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'outcome_id',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'meeting_type',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'meeting_id',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'advisor_uuid',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'candidate_status_id',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'meeting_ends',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'meeting_subject',
          area: 'row',
          visible: false,
        },
        {
          dataField: 'status_name',
          area: 'row',
          visible: false,
        },
        {
          caption: t('freq'),
          dataField: 'outcome_id',
          summaryType: 'sum',
          area: 'data',
          summaryDisplayMode: 'percentOfColumnGrandTotal',
          dataType: 'number',
          format: '#0.0%',
        },
        {
          caption: t('total'),
          dataField: 'outcome_id',
          summaryType: 'count',
          area: 'data',
          summaryDisplayMode: 'none',
          dataType: 'number',
        },
      ],
      store: commitmentMeetings.map((m) => ({
        ...m,
        meeting_starts: m.meeting_starts.substring(0, 10),
      })),
    });

    setReportData(dataSource);
  }, [commitmentMeetings, t]);

  useEffect(() => {
    if (commitmentMeetings !== undefined) {
      setData(commitmentMeetings);
    }
  }, [commitmentMeetings]);

  const columns = [
    {
      caption: t('candidate-name'),
      field: 'candidate_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('current-status'),
      field: 'status_name',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('meeting-date'),
      field: 'meeting_starts',
      dataType: 'date',
      calculateCellValue: (d) => d.meeting_starts.substring(0, 10),
      sortOrder: 'desc',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('meeting-outcome'),
      field: 'outcome',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('days-until-enrolled'),
      field: 'days_until_enrolled',
      visible: true,
      allowFixing: true,
    },
    {
      caption: t('advisor'),
      field: 'advisor_name',
      visible: false,
      allowFixing: true,
    },
  ];

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('CommitmentMeetings');

    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'LeadConversion.xlsx');
      });
    });
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid item md={12}>
        <BaseTable name="CommitmentMeetings" data={data} keyExpr="meeting_id" columns={columns} />
      </Grid>
      <Grid item md={12}>
        <Card>
          <PivotGrid
            id="commitment-meeting"
            dataSource={reportData}
            allowSortingBySummary
            allowSorting
            allowFiltering
            showBorders={false}
            onExporting={onExporting}
          >
            <FieldChooser enabled height={400} />
            <Export enabled />
            <Scrolling mode="virtual" />
          </PivotGrid>
        </Card>
      </Grid>
    </Grid>
  );
}

CommitmentMeetings.propTypes = {
  parameters: PropTypes.instanceOf({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};

export default CommitmentMeetings;
