import { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Functions
import { fetchAccounts } from 'reducers/accountsSlice';

// @mui material components
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// DevExtreme components
import DataGrid, {
  ColumnFixing,
  Editing, Export,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.material.blue.light.css';

// Components
import BaseLayout from 'layouts/components/BaseLayout';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

// Functions
import { renderColumns } from 'Util';

const selector = (state) => ({
  accounts: state.account.accounts,
});

function Billing() {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });
  const { accounts } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  const dataGrid = useRef(null);
  const allowedPageSizes = [10, 30, 50, 'all'];

  const handleEditing = (e) => {
    navigate(`/billing/accounts/${e.key}`);
  };

  const handleExport = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('accounts');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'accounts.xlsx');
      });
    });
  };

  const handleStateReset = () => {
    dataGrid.current.instance.state(null);
  };

  const columns = [
    {
      caption: t('last-name', { keyPrefix: 'common' }),
      field: 'last_name',
    },
    {
      caption: t('first-name', { keyPrefix: 'common' }),
      field: 'first_name',
    },
    {
      caption: t('balance'),
      field: 'balance',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
    {
      caption: t('last-payment-date'),
      field: 'last_payment_date',
      dataType: 'date',
    },
    {
      caption: t('last-payment-amount'),
      field: 'last_payment_amount',
      dataType: 'number',
      format: { type: 'currency', precision: 2 },
    },
    {
      caption: t('status'),
      field: 'status',
      calculateCellValue: (data) => (data.status === 'g' ? t('good') : t('overdue')),
    },
  ];

  return (
    <BaseLayout>
      <SuiBox>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <SuiButton variant="text" color="info" onClick={handleStateReset}>
              {t('reset-filters', { keyPrefix: 'common' })}
            </SuiButton>
          </Grid>
        </Grid>
        <Paper>
          <DataGrid
            id="accounts"
            ref={dataGrid}
            dataSource={accounts}
            keyExpr="candidate_id"
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            onEditingStart={handleEditing}
            onExporting={handleExport}
          >
            <ColumnFixing enabled />
            <Editing
              mode="row"
              allowUpdating
              allowDeleting={false}
              allowAdding={false}
            />
            <Export
              enabled
            />
            <HeaderFilter visible />
            <Scrolling rowRenderingMode="virtual" />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={30} />
            <Pager
              visible
              allowedPageSizes={allowedPageSizes}
              displayMode="full"
              showPageSizeSelector
              showInfo
              showNavigationButtons
            />
            <SearchPanel
              visible
              width={240}
              placeholder={t('search', { keyPrefix: 'common' })}
            />
            <StateStoring
              enabled
              type="localStorage"
              storageKey="accounts-grid"
            />
            {renderColumns(columns)}
          </DataGrid>
        </Paper>
      </SuiBox>
    </BaseLayout>
  );
}

export default Billing;
