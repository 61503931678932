import { shallowEqual, useSelector } from 'react-redux';

// Util functions
import { checkPermission, setTitle } from 'Util';

// Components
import AccountSummary from 'layouts/accounts/components/Summary';
import PaymentPlanSelector from './components/PaymentPlanSelector';
import LoadFile from './components/LoadFile';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function Billing() {
  const { currentUser } = useSelector(selector, shallowEqual);
  setTitle('Candidate Billing and Payments');

  return (
    <>
      {checkPermission('CBPL', currentUser) && (
        <LoadFile />
      )}
      {checkPermission('CBPP', currentUser) && (
        <PaymentPlanSelector />
      )}
      {checkPermission('CBPS', currentUser) && (
        <AccountSummary />
      )}
    </>
  );
}

export default Billing;
