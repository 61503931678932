import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import DefaultItem from 'layouts/components/Items/DefaultItem';

function Events({ title, events }) {
  return (
    <Card sx={{ height: '100%' }}>
      <SuiBox pt={2} px={2} lineHeight={1}>
        <SuiTypography variant="h6" fontWeight="medium">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        {Object.keys(events).length > 0
          && events.map((e, i) => (
            <DefaultItem
              key={`event${i}`}
              icon={e.icon}
              title={e.title}
              description={e.description}
            />
          ))}
      </SuiBox>
    </Card>
  );
}

Events.defaultProps = {
  title: 'Events',
};

Events.propTypes = {
  title: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape(
      {
        title: PropTypes.string,
        description: PropTypes.string,
      },
    ),
  ).isRequired,
};
export default Events;
