import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';

// Functions
import { checkPermission, setTitle } from 'Util';
import dayjs from 'dayjs';

// Components
import ReportsBaseLayout from 'layouts/reports/components/BaseLayout';
import EnglishEnrollmentDays from './components/EnglishEnrollmentDays';
import EnglishEnrollmentDaysParameters
  from './components/Parameters/EnglishEnrollmentDaysParameters';
import LeadConversion from './components/LeadConversion';
import LeadConversionParameters
  from './components/Parameters/LeadConversionParameters';
import CommitmentMeetings from './components/CommitmentMeetings';
import CommitmentMeetingsParameters from './components/Parameters/CommitmentMeetingsParameters';
import EnglishLearningSpan from './components/EnglishLearningSpan';
import EnglishLearningSpanParameters from './components/Parameters/EnglishLearningSpanParameters';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function AnalyticsReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);

  const [selected, setSelected] = useState({ value: '' });
  const [openParams, setOpenParams] = useState(false);
  const [parameters, setParameters] = useState(null);

  setTitle('Analytics Reports');

  const openParameters = () => {
    setOpenParams(true);
  };

  const closeParameters = () => {
    setOpenParams(false);
  };

  const handleParameters = (params) => {
    setParameters(params);
  };

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R201', currentUser)) {
      r.push({
        value: 'english-enrollment-days',
        label: t('english-enrollment-days'),
        parameters: true,
        defaultParameters: {
          date: new Date().toISOString().split('T')[0],
          statuses: null,
        },
      });
    }

    if (checkPermission('R202', currentUser)) {
      r.push({
        value: 'leads-conversion',
        label: t('leads-conversion'),
        parameters: true,
        defaultParameters: {
          from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        },
      });
    }

    if (checkPermission('R203', currentUser)) {
      r.push({
        value: 'commitment-meetings',
        label: t('commitment-meetings'),
        parameters: true,
        defaultParameters: {
          from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        },
      });
    }

    if (checkPermission('R204', currentUser)) {
      r.push({
        value: 'english-level-learning-span',
        label: t('english-level-learning-span'),
        parameters: true,
        defaultParameters: { statuses: [2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17] },
      });
    }

    return r;
  }, [currentUser, t]);

  return (
    <ReportsBaseLayout
      reports={reports}
      selectReport={setSelected}
      showParameters={selected.parameters}
      openParamsFunc={openParameters}
    >
      {selected.value === 'english-enrollment-days' && (
        <div>
          <EnglishEnrollmentDays
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <EnglishEnrollmentDaysParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'leads-conversion' && (
        <div>
          <LeadConversion
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <LeadConversionParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'commitment-meetings' && (
        <div>
          <CommitmentMeetings
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <CommitmentMeetingsParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'english-level-learning-span' && (
        <div>
          <EnglishLearningSpan
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <EnglishLearningSpanParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
    </ReportsBaseLayout>
  );
}

export default AnalyticsReports;
