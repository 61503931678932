import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AccountService from 'services/account.service';
/* eslint-disable object-curly-newline */
import { fulfilledReducer, pendingReducer, rejectionReducer } from '../Util';

export const fetchAccounts = createAsyncThunk(
  'account/fetchAccounts',
  async (payload, { rejectWithValue }) => {
    try {
      return await AccountService.getAccounts();
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAccount = createAsyncThunk(
  'account/fetchAccount',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AccountService.getAccount(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAccountBalance = createAsyncThunk(
  'account/fetchAccountBalance',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AccountService.getBalance(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAccountInvoices = createAsyncThunk(
  'account/fetchAccountInvoices',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AccountService.getInvoices(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAccountPayments = createAsyncThunk(
  'account/fetchAccountPayments',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AccountService.getPayments(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAccountExpenses = createAsyncThunk(
  'account/fetchAccountExpenses',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AccountService.getExpenses(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchCandidateInvoices = createAsyncThunk(
  'account/fetchCandidateInvoices',
  async (payload, { rejectWithValue }) => {
    const {
      id,
      status,
    } = payload;
    try {
      return await AccountService.getCandidateInvoices(id, status);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

export const fetchAccountTransactions = createAsyncThunk(
  'account/fetchAccountTransactions',
  async (payload, { rejectWithValue }) => {
    const { id } = payload;
    try {
      return await AccountService.getTransactions(id);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  },
);

/* eslint-disable no-param-reassign */
export const accountsSlice = createSlice({
  name: 'account',
  initialState: {
    accountInfo: {},
    balance: {},
    invoices: [],
    payments: [],
    expenses: [],
    transactions: [],
    accounts: [],
    importData: {},
    message: '',
  },
  reducers: {
    clearAccountInfo: (state) => {
      state.accountInfo = {};
      state.balance = {};
      state.invoices = [];
      state.payments = [];
      state.expenses = [];
      state.transactions = [];
      state.accounts = [];
      state.importData = {};
      state.message = '';
    },
    setImportData: (state, action) => {
      state.importData = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addMatcher(
      (action) => action.type.endsWith('/rejected'),
      rejectionReducer,
    )
    .addMatcher((action) => action.type.endsWith('/pending'), pendingReducer)
    .addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const performedAction = action.type.split('/');
        if (performedAction[0] === 'account') {
          switch (performedAction[1]) {
            case 'fetchAccounts':
              fulfilledReducer(state, action);
              state.accounts = action.payload;
              break;
            case 'fetchAccount':
              fulfilledReducer(state, action);
              state.accountInfo = action.payload;
              break;
            case 'fetchAccountBalance':
              fulfilledReducer(state, action);
              state.balance = action.payload;
              break;
            case 'fetchAccountInvoices':
            case 'fetchCandidateInvoices':
              fulfilledReducer(state, action);
              state.invoices = action.payload;
              break;
            case 'fetchAccountPayments':
              fulfilledReducer(state, action);
              state.payments = action.payload;
              break;
            case 'fetchAccountExpenses':
              fulfilledReducer(state, action);
              state.expenses = action.payload;
              break;
            case 'fetchAccountTransactions':
              fulfilledReducer(state, action);
              state.transactions = action.payload;
              break;
            default:
              fulfilledReducer(state, action);
              state.message = action.payload;
              break;
          }
        }
      },
    ),
});

export const {
  clearAccountInfo,
  setImportData,
} = accountsSlice.actions;

export default accountsSlice.reducer;
