import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// @material-ui core components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Reducer functions
import {
  fetchCandidateNursingBoards,
  setCurrentSubView,
  setNursingBoards,
} from 'reducers/candidatesSlice';
import { fetchStates } from 'reducers/commonSlice';
import { fetchDocuments } from 'reducers/documentsSlice';

// Components
import DefaultNursingBoardCard from 'layouts/components/Cards/NursingBoardCard/DefaultNursingBoardCard';

// Functions
import { getCandidateUUID, setTitle } from 'Util';

const selector = (state) => ({
  isAdmin: state.auth.isAdmin,
  editing: state.candidate.editing,
  nursingBoards: state.candidate.nursingBoards,
  states: state.common.states,
  documents: state.document.documents,
});

function BoardNursing() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.nursing-board',
  });
  const {
    isAdmin,
    editing,
    nursingBoards,
    states,
    documents,
  } = useSelector(
    selector,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(setCurrentSubView('nursing-board'));
    dispatch(fetchStates({ id: 233 }));
    dispatch(fetchDocuments());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateNursingBoards({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Board of Nursing');

  const isValid = (key, value, f) => {
    switch (key) {
      case 'state_id':
        return (
          value !== ''
          && value !== undefined
        );
      case 'date':
        return (
          f.state_id !== ''
          && f.state_id !== undefined
        );
      case 'applicant_id':
        return (
          f.state_id !== ''
          && f.state_id !== undefined
        );
      case 'username':
        return (
          f.state_id !== ''
          && f.state_id !== undefined
        );
      case 'password':
        return (
          f.state_id !== ''
          && f.state_id !== undefined
        );
      default:
        return (
          f.state_id !== ''
          && f.state_id !== undefined
        );
    }
  };

  const setValue = (key, value, i) => {
    dispatch(
      setNursingBoards(
        nursingBoards.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
              valid: isValid(key, value, nursingBoards[i]),
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleAddNursingBoard = () => {
    dispatch(
      setNursingBoards(
        nursingBoards.concat({
          id: undefined,
          state_id: undefined,
          application_by: undefined,
          date: '',
          applicant_id: '',
          username: '',
          password: '',
          changed: true,
          valid: false,
          candidate_nursing_board_documents: [],
        }),
      ),
    );
  };

  const handleRemoveNursingBoard = (i) => {
    if (nursingBoards[i].id !== undefined) {
      setValue('_destroy', true, i);
    } else {
      dispatch(setNursingBoards([...nursingBoards.slice(0, i), ...nursingBoards.slice(i + 1)]));
    }
  };

  return (
    <SuiBox component="form">
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <SuiTypography variant="h4" fontWeight="medium">
              {t('board-of-nursing')}
            </SuiTypography>
            {isAdmin && editing && (
              <SuiBox mt={1} mb={2}>
                <SuiTypography variant="body2" color="text">
                  {t('board-of-nursing-description')}
                </SuiTypography>
              </SuiBox>
            )}
          </Grid>
          <Grid item md={6}>
            <SuiBox display="flex" justifyContent="flex-end">
              <SuiButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddNursingBoard}
              >
                {t('new-board-of-nursing')}
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12}>
            <SuiBox pb={1} px={1}>
              {Object.keys(nursingBoards).length > 0
                && nursingBoards.map((b, i) => (
                  <div
                    key={`nursing-board-${i}`}
                    style={{
                      // eslint-disable-next-line no-underscore-dangle
                      display: (b._destroy !== undefined ? 'none' : 'block'),
                    }}
                  >
                    <DefaultNursingBoardCard
                      i={i}
                      e={b}
                      ops={{
                        states,
                        documents,
                      }}
                      setValue={setValue}
                      removeFunc={handleRemoveNursingBoard}
                    />
                    <Divider />
                  </div>
                ))}
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default BoardNursing;
