// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Other components
import PhoneInput from 'react-phone-number-input';
import 'assets/styles/phone-input.css';

function FormPhoneInput({ label, readOnly, ...rest }) {
  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      <PhoneInput readOnly={readOnly} {...rest} />
    </SuiBox>
  );
}

// Setting admin values for the props of FormPhoneInput
FormPhoneInput.defaultProps = {
  label: ' ',
  readOnly: false,
};

// Typechecking props for FormPhoneInput
FormPhoneInput.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default FormPhoneInput;
