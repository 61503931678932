import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchCandidateProfile,
  setCreated,
  setFailed,
  setSaved,
  updateAdvisors,
  updateCoaches,
} from 'reducers/candidatesSlice';
import { fetchAdvisorsInfo, fetchCoachesInfo } from 'reducers/usersSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// DevExtreme components
// Components
import FormSelect from 'layouts/components/FormSelect';

// Functions
import { failedCandidateMessage, getCandidateUUID, savedCandidateMessage } from 'Util';

// Other
import 'assets/styles/advisors-drag.css';
import PropTypes from 'prop-types';

const selector = (state) => ({
  editing: state.candidate.editing,
  profile: state.candidate.profile,
  advisors: state.user.advisors,
  coaches: state.user.coaches,
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
});

function Advisors({ type }) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.advisors' });
  const {
    editing,
    profile,
    advisors,
    coaches,
    created,
    saved,
    failed,
    errors,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    if (type === 'advisors') {
      dispatch(fetchAdvisorsInfo());
    }
    if (type === 'coaches') {
      dispatch(fetchCoachesInfo());
    }

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
    }
  }, [dispatch, editing, type, uuid]);

  const [advisorsExpanded, setAdvisorsExpanded] = useState(true);
  const [advisorsMenu, setAdvisorsMenu] = useState(null);
  const [available, setAvailable] = useState([]);
  const [assigned, setAssigned] = useState([]);

  const openAdvisorsMenu = (event) => setAdvisorsMenu(event.currentTarget);
  const closeAdvisorsMenu = () => setAdvisorsMenu(null);
  const handleAdvisorsExpand = () => {
    closeAdvisorsMenu();
    setAdvisorsExpanded(!advisorsExpanded);
  };

  const setAvailableAdvisors = (assignedAdvisors) => {
    const groups = {
      advisors,
      coaches,
    };

    const availableAdvisors = groups[type].filter((a) => !assignedAdvisors
      .some((aa) => a.uuid === aa.value)).map(
      (a) => (
        {
          value: a.value,
          label: a.label,
          default: false,
        }
      ),
    );
    setAvailable(availableAdvisors.concat(assignedAdvisors));
  };

  useEffect(() => {
    const groups = {
      advisors: profile.advisors,
      coaches: profile.coaches,
    };

    if (groups[type] !== undefined) {
      const assignedAdvisors = groups[type].map((a) => ({
        value: a.uuid,
        label: a.name,
        default: a.default,
      }));
      setAvailableAdvisors(assignedAdvisors);
      setAssigned(assignedAdvisors);
    }
    // eslint-disable-next-line
  }, [type, profile, advisors, coaches]);

  const handleSaveAdvisors = () => {
    if (type === 'advisors') {
      dispatch(updateAdvisors({
        id: uuid,
        advisors: assigned,
      }));
    }
    if (type === 'coaches') {
      dispatch(updateCoaches({
        id: uuid,
        advisors: assigned,
      }));
    }
  };

  const handleAssignAdvisor = (newAssigned) => {
    setAssigned(newAssigned);
  };

  const handleDefaultAdvisor = (e) => {
    let assignedAdvisors = [];
    if (assigned.some((a) => a.value === e.value)) {
      assignedAdvisors = assigned
        .map((a) => {
          if (a.value === e.value) {
            return {
              ...a,
              default: true,
            };
          }
          return {
            ...a,
            default: false,
          };
        });
    } else {
      assignedAdvisors = assigned
        .map((a) => ({
          ...a,
          default: false,
        }))
        .concat({
          value: e.value,
          label: e.label,
          default: true,
        });
    }
    setAssigned(assignedAdvisors);
    setAvailableAdvisors(assignedAdvisors);
  };

  let current;
  let title;
  let placeholder;

  if (type === 'advisors') {
    current = t('current-advisor');
    title = t('advisors');
    placeholder = t('advisors');
  }
  if (type === 'coaches') {
    current = t('current-coach');
    title = t('coaches');
    placeholder = t('coaches');
  }

  if (created) {
    dispatch(setCreated(false));
    savedCandidateMessage(t);
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <Card id="advisors" sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openAdvisorsMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={title}
      />
      <Menu
        anchorEl={advisorsMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(advisorsMenu)}
        onClose={closeAdvisorsMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleAdvisorsExpand}
        >
          {advisorsExpanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <Collapse in={advisorsExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item md={12}>
              <FormSelect
                label={current}
                options={available}
                onChange={handleDefaultAdvisor}
                value={available.filter((a) => a.default === true)}
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                multiple
                id="advisors"
                options={available}
                getOptionLabel={(option) => option.label}
                value={assigned}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={placeholder}
                  />
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    handleAssignAdvisor(newValue);
                  } else if (newValue && newValue.inputValue) {
                    handleAssignAdvisor(newValue.inputValue);
                  } else {
                    handleAssignAdvisor(newValue);
                  }
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <CardActions>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="center"
        >
          <SuiButton
            size="small"
            color="info"
            onClick={handleSaveAdvisors}
          >
            {t('save')}
          </SuiButton>
        </Grid>
      </CardActions>
    </Card>
  );
}

Advisors.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Advisors;
