import { useTranslation } from 'react-i18next';

// @material-ui core components
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import { TableCell, TableContainer, TableHead } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';

// Soft UI Dashboard PRO React components
import SuiTypography from 'components/SuiTypography';
import SuiBox from 'components/SuiBox';

function Notifications() {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  return (
    <Card id="notifications">
      <SuiBox p={3} lineHeight={1}>
        <SuiBox mb={1}>
          <SuiTypography variant="h5">{t('notifications')}</SuiTypography>
        </SuiBox>
        <SuiTypography variant="button" color="text" fontWeight="regular">
          {t('notifications-description')}
        </SuiTypography>
      </SuiBox>
      <SuiBox pb={3} px={3}>
        <SuiBox minWidth="auto" sx={{ overflow: 'scroll' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('activity')}</TableCell>
                  <TableCell align="right">{t('email')}</TableCell>
                  <TableCell align="right">{t('push')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={1}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <SuiBox lineHeight={1.4}>
                      <SuiTypography display="block" variant="button" fontWeight="regular">
                        Comments
                      </SuiTypography>
                      <SuiTypography variant="caption" color="text" fontWeight="regular">
                        Notify when another user comments your item.
                      </SuiTypography>
                    </SuiBox>
                  </TableCell>
                  <TableCell align="right">
                    <Switch defaultChecked />
                  </TableCell>
                  <TableCell align="right">
                    <Switch defaultChecked />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default Notifications;
