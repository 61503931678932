import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Reduce functions
import { setImportData } from 'reducers/accountsSlice';

// @mui material components
import { DropzoneDialog } from 'material-ui-dropzone';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Other
import * as Excel from 'exceljs';

// Functions
import { getCandidateUUID } from 'Util';
import dayjs from 'dayjs';

function LoadFile() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.billing' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = getCandidateUUID();

  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenUpload = () => setOpenUpload(true);
  const handleCloseUpload = () => setOpenUpload(false);
  const handleUpload = (files) => {
    setOpenUpload(false);

    const workbook = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(files[0]);

    reader.onload = () => {
      const buffer = reader.result;
      workbook.xlsx.load(buffer).then((wb) => {
        const sheet = wb.worksheets[0];
        const services = [];
        const expenses = [];
        const payments = [];
        const invoices = [];

        const status = sheet.getCell(3, 4).value;
        const firstPayment = dayjs(sheet.getCell(4, 4).value).add(8, 'hour').format();
        const paymentPlan = sheet.getCell(5, 4).value;
        const deferredPlan = sheet.getCell(6, 4).value;
        const payNas = sheet.getCell(8, 4).value;
        const payBsr = sheet.getCell(9, 4).value;
        const deferredPayment = sheet.getCell(10, 4).value;
        const downPayment = sheet.getCell(11, 4).value;
        const monthlyAmount = sheet.getCell(12, 4).value;
        const monthlyPayments = sheet.getCell(13, 4).value;
        const englishAcademy = sheet.getCell(24, 4).value;
        const englishLevel = sheet.getCell(25, 4).value;

        let reachNoteRow = false;

        sheet.eachRow((row, rowIndex) => {
          if (rowIndex >= 15 && rowIndex <= 22) {
            if (row.values[5] !== undefined && row.values[5] !== null) {
              if (row.values[5].toString().toLowerCase() === 'x') {
                services.push(row.values[2]);
              }
            }
          }

          if (rowIndex >= 27 && rowIndex <= 37) {
            if (row.values[4] !== undefined) {
              expenses.push({
                date: dayjs(row.values[7]).add(8, 'hour').format(),
                vendor: row.values[1].replace('  ', ' '),
                amount: row.values[4],
              });
            }
          }

          if (rowIndex >= 43) {
            if (row.values[1] !== undefined && row.values[1] !== null) {
              if (row.values[1].toString().startsWith('Note')) {
                reachNoteRow = true;
              } else if (!reachNoteRow) {
                if (row.values[3].toString().toLowerCase().startsWith('invoice')) {
                  invoices.push({
                    date: dayjs(row.values[1]).add(8, 'hour').format(),
                    item: row.values[3].toString().replace('  ', ' '),
                    value: row.values[7],
                  });
                } else {
                  payments.push({
                    date: dayjs(row.values[1]).add(8, 'hour').format(),
                    item: row.values[3].toString().replace('  ', ' '),
                    value: row.values[8],
                  });
                }
              }
            }
          }
        });

        dispatch(setImportData({
          status,
          firstPayment,
          paymentPlan,
          deferredPlan,
          payNas,
          payBsr,
          deferredPayment,
          downPayment,
          monthlyAmount,
          monthlyPayments,
          englishAcademy,
          englishLevel,
          services,
          expenses,
          payments,
          invoices,
        }));

        navigate(`/candidates/edit/import-financial/${uuid}`);
      });
    };
  };

  return (
    <SuiBox display="flex" justifyContent="flex-end" p={1}>
      <SuiBox mr={1}>
        <SuiButton variant="text" color="info" onClick={handleOpenUpload}>
          {t('import')}
        </SuiButton>
      </SuiBox>
      <DropzoneDialog
        acceptedFiles={[
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        cancelButtonText={t('cancel', { keyPrefix: 'common' })}
        submitButtonText={t('upload', { keyPrefix: 'common' })}
        maxFileSize={10000000}
        filesLimit={1}
        open={openUpload}
        onClose={handleCloseUpload}
        showPreviews
        showFileNamesInPreview
        onSave={(files) => handleUpload(files)}
      />
    </SuiBox>
  );
}

export default LoadFile;
