import PropTypes from 'prop-types';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiAvatar from 'components/SuiAvatar';
import SuiTypography from 'components/SuiTypography';

// Images
import imgAvatar from 'assets/images/default-avatar.svg';

function Header({ name, role, image }) {
  return (
    <Card id="profile">
      <SuiBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SuiAvatar
              src={image}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <SuiBox height="100%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h5" fontWeight="medium">
                {name}
              </SuiTypography>
              <SuiTypography variant="button" color="text" fontWeight="medium">
                {role}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

Header.defaultProps = {
  name: '',
  role: '',
  image: imgAvatar,
};

Header.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  image: PropTypes.string,
};

export default Header;
