import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Reducer functions
import {
  assignCandidateNursingBoardDoc,
  deleteCandidateNursingBoardDoc,
  uploadCandidateNursingBoardDoc,
} from 'reducers/candidatesSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import FormSelect from 'layouts/components/FormSelect';
import FormField from 'layouts/components/FormField';
import FormPasswordInput from 'layouts/components/FormPasswordInput';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';

// Utils
import { defaultValue, findSelectValue, getCandidateUUID } from 'Util';

function DefaultNursingBoardCard(
  {
    i,
    e,
    ops,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.nursing-board' });
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [expanded, setExpanded] = useState(true);
  const [nursingBoardMenu, setNursingBoardMenu] = useState(null);
  const [state, setState] = useState(null);
  const [boardNursingDocs, setBoardNursingDocs] = useState([]);
  const [assignedNursingDocs, setAssignedNursingDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const openNursingBoardMenu = (event) => setNursingBoardMenu(event.currentTarget);
  const closeNursingBoardMenu = () => setNursingBoardMenu(null);

  const applicants = [
    {
      value: 1,
      label: t('examination'),
    },
    {
      value: 2,
      label: t('endorsement'),
    },
  ];

  const handleExpand = () => {
    closeNursingBoardMenu();
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (e.state_id !== undefined && e.state_id !== null) {
      const stateVal = findSelectValue(ops.states, e.state_id);

      if (stateVal !== undefined) {
        setState(stateVal.name);
      }
    }

    const allBoardDocs = ops.documents.filter((doc) => doc.document_type_id === 7);
    const assgBoardDocs = e.candidate_nursing_board_documents
      .filter((d) => d.document.document_type_id === 7);
    setAssignedNursingDocs(assgBoardDocs);
    setBoardNursingDocs(allBoardDocs.filter(
      (ad) => !assgBoardDocs.some((cd) => ad.id === cd.document.id),
    ));
  }, [ops, e]);

  const handleAddDocument = (evn) => {
    evn.preventDefault();

    dispatch(
      assignCandidateNursingBoardDoc({
        id: e.id,
        candidateId: uuid,
        documentId: selectedDoc,
      }),
    );
  };

  const handleUploadDocument = (files, candidateId, documentId) => {
    dispatch(
      uploadCandidateNursingBoardDoc({
        id: e.id,
        candidateId,
        documentId,
        file: files[0],
      }),
    );
  };

  const handleRemoveDocument = (documentId, candidateId, documentType) => {
    dispatch(
      deleteCandidateNursingBoardDoc({
        id: e.id,
        candidateId,
        typeId: documentType,
        documentId,
      }),
    );
  };

  return (
    <Card sx={{ overflow: 'visible' }} key={i}>
      <CardHeader
        action={(
          <IconButton onClick={openNursingBoardMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={state}
      />
      <Menu
        anchorEl={nursingBoardMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(nursingBoardMenu)}
        onClose={closeNursingBoardMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <SuiBox mb={2}>
              <Grid
                container
                direction="row"
                spacing={3}
              >
                <Grid item md={4}>
                  <FormSelect
                    label={t('state')}
                    placeholder={t('state')}
                    options={ops.states}
                    id={`states[${i}]`}
                    value={
                      ops.states !== undefined
                        ? findSelectValue(ops.states, e.state_id, i) : undefined
                    }
                    error={e.state_id ? false
                      : !e.state_id || e.state_id === 0}
                    onChange={(event) => {
                      setValue('state_id', event.value, i);
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormSelect
                    label={t('application-by')}
                    options={applicants}
                    value={findSelectValue(applicants, e.application_by)}
                    onChange={(event) => {
                      setValue('application_by', event.value, i);
                    }}
                  />
                </Grid>
                <Grid item md={2} pr={1}>
                  <FormField
                    label={t('date')}
                    type="date"
                    onChange={(event) => {
                      setValue('date', event.target.value, i);
                    }}
                    value={defaultValue(e.date)}
                  />
                </Grid>
                <Grid item md={2} pr={1}>
                  <FormField
                    label={t('expiration')}
                    type="date"
                    onChange={(event) => {
                      setValue('expiration', event.target.value, i);
                    }}
                    value={defaultValue(e.expiration)}
                  />
                </Grid>
                <Grid item md={4} pr={1}>
                  <FormField
                    label={t('applicant-id')}
                    onChange={(event) => {
                      setValue('applicant_id', event.target.value, i);
                    }}
                    value={defaultValue(e.applicant_id)}
                  />
                </Grid>
                <Grid item md={4} pr={1}>
                  <FormField
                    label={t('username')}
                    onChange={(event) => {
                      setValue('username', event.target.value, i);
                    }}
                    value={defaultValue(e.username)}
                  />
                </Grid>
                <Grid item md={4} pr={1}>
                  <FormPasswordInput
                    label={t('password')}
                    inputProps={{ autoComplete: '' }}
                    onChange={(event) => {
                      setValue('password', event.target.value, i);
                    }}
                    value={defaultValue(e.password)}
                  />
                </Grid>
                <Grid item md={3} pr={1}>
                  {e.id !== undefined && (
                    <FormSelect
                      label={t('assign-document')}
                      placeholder={t('select-document')}
                      options={boardNursingDocs}
                      onChange={(evn) => {
                        setSelectedDoc(evn.value);
                      }}
                    />
                  )}
                </Grid>
                <Grid item md={3} pr={1} mt={5}>
                  {e.id !== undefined && (
                    <IconButton
                      aria-label="add"
                      onClick={(evn) => {
                        handleAddDocument(evn);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item md={6} pr={1} />
                {Object.keys(assignedNursingDocs).length > 0
                  && assignedNursingDocs.map((f, j) => (
                    <Grid item xs={12} md={4} pr={1}>
                      <DefaultDocumentCard
                        key={`result-document-${j}`}
                        id={f.id}
                        candidateId={uuid}
                        description={f.document.description}
                        title={f.document.name}
                        fileUrl={f.file_url}
                        filesLimit={f.document.uploads_number}
                        maxFilesSize={f.document.max_size}
                        changeFunc={(files, candidateId, docId) => {
                          handleUploadDocument(files, candidateId, docId);
                        }}
                        removeFunc={(id, candidateId) => {
                          handleRemoveDocument(id, candidateId);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </SuiBox>
          </CardContent>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultNursingBoardCard.propTypes = {
  i: PropTypes.number.isRequired,
  e: PropTypes.shape(
    {
      state_id: PropTypes.number,
      application_by: PropTypes.number,
      date: PropTypes.string,
      expiration: PropTypes.string,
      applicant_id: PropTypes.string,
      username: PropTypes.string,
      password: PropTypes.string,
      id: PropTypes.number,
      candidate_nursing_board_documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_url: PropTypes.string,
          document: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            uploads_number: PropTypes.number,
          }),
        }),
      ),
    },
  ).isRequired,
  ops: PropTypes.shape({
    states: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultNursingBoardCard;
