import { shallowEqual, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Functions
import { checkPermission, setTitle } from 'Util';

// Components
import ReportsBaseLayout from '../components/BaseLayout';
import Potentials from './components/Potentials';
import PotentialsParameters from './components/Parameters/Potentials';
import JobApplicationsParameters from './components/Parameters/JobApplications';
import JobApplications from './components/JobApplications';
import ImmigrationParameters from './components/Parameters/Immigration';
import Immigration from './components/Immigration';

const selector = (state) => ({
  currentUser: state.auth.user,
});

function RecruitmentReports() {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const { currentUser } = useSelector(selector, shallowEqual);

  const [selected, setSelected] = useState({ value: '' });
  const [openParams, setOpenParams] = useState(false);
  const [parameters, setParameters] = useState(null);

  setTitle('Recruitment Reports');

  const openParameters = () => {
    setOpenParams(true);
  };

  const closeParameters = () => {
    setOpenParams(false);
  };

  const handleParameters = (params) => {
    setParameters(params);
  };

  const reports = useMemo(() => {
    const r = [];

    if (checkPermission('R301', currentUser)) {
      r.push({
        value: 'potentials',
        label: t('potentials'),
        parameters: true,
        defaultParameters: { statuses: [11, 5] },
      });
    }

    if (checkPermission('R302', currentUser)) {
      r.push({
        value: 'job-applications',
        label: t('job-applications'),
        parameters: true,
        defaultParameters: { statuses: [11, 5] },
      });
    }

    if (checkPermission('R303', currentUser)) {
      r.push({
        value: 'immigration',
        label: t('immigration'),
        parameters: true,
        defaultParameters: { statuses: [7] },
      });
    }

    return r;
  }, [currentUser, t]);

  return (
    <ReportsBaseLayout
      reports={reports}
      selectReport={setSelected}
      showParameters={selected.parameters}
      openParamsFunc={openParameters}
    >
      {selected.value === 'potentials' && (
        <div>
          <Potentials parameters={parameters !== null ? parameters : selected.defaultParameters} />
          <PotentialsParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'job-applications' && (
        <div>
          <JobApplications
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <JobApplicationsParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
      {selected.value === 'immigration' && (
        <div>
          <Immigration
            parameters={parameters !== null ? parameters : selected.defaultParameters}
          />
          <ImmigrationParameters
            parameters={parameters !== null ? parameters : selected.defaultParameters}
            handleParamsFunc={handleParameters}
            openParameters={openParams}
            closeParamsFunc={closeParameters}
          />
        </div>
      )}
    </ReportsBaseLayout>
  );
}

export default RecruitmentReports;
