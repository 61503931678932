// react components
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  assignCandidateLanguageTestDocument,
  deleteCandidateLanguageTestDocument,
  uploadCandidateLanguageTestDocument,
} from 'reducers/candidatesSlice';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Functions
import { defaultValue, findSelectValue, isDocumentsUploaded } from 'Util';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import DefaultDocumentCard from '../DocumentCards/DefaultDocumentCard';

function LanguageTestCard(
  {
    i,
    languageProficiency,
    candidateId,
    e,
    ops,
    setValue,
    handleSelect,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.language-proficiency' });
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [menu, setMenu] = useState(null);
  const [documentMenu, setDocumentMenu] = useState(null);
  const [expandedDocumentState, setExpandedDocumentState] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availTestDocs, setAvailTestDocs] = useState([]);
  const [calcExpiration, setCalcExpiration] = useState(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const handleExpand = () => {
    closeMenu();
    setExpanded(!expanded);
  };

  const openDocumentMenu = (event) => setDocumentMenu(event.currentTarget);
  const closeDocumentMenu = () => setDocumentMenu(null);

  const yesNoStatus = [
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
  ];

  const handleDocumentExpand = () => {
    closeDocumentMenu();
    setExpandedDocumentState(!expandedDocumentState);
  };

  const handleAddDocument = () => {
    dispatch(assignCandidateLanguageTestDocument({
      proficiencyId: e.candidate_language_proficiency_id,
      candidateId,
      testId: e.id,
      documentId: selectedDoc,
    }));
    setSelectedDoc(null);
  };

  const handleUploadDocument = (files, documentId) => {
    dispatch(uploadCandidateLanguageTestDocument({
      proficiencyId: e.candidate_language_proficiency_id,
      candidateId,
      testId: e.id,
      documentId,
      file: files[0],
    }));
  };

  const handleDeleteDocument = (id) => {
    dispatch(deleteCandidateLanguageTestDocument({
      proficiencyId: e.candidate_language_proficiency_id,
      candidateId,
      testId: e.id,
      documentId: id,
    }));
  };

  const setSchedule = (value) => {
    setValue('schedule', value, languageProficiency, i);
    setCalcExpiration(true);
  };

  if (calcExpiration) {
    const expiration = Date.parse(`${e.schedule}T00:00:00`);
    expiration.add(24).months();
    setCalcExpiration(false);
    setValue('expiration', expiration.toString('yyyy-MM-dd'), languageProficiency, i);
  }

  useEffect(() => {
    setAvailTestDocs(ops.documents.filter(
      (ad) => !e.documents.some((cd) => ad.id === cd.document.id),
    ));
  }, [e.documents, ops.documents]);

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('test')} ${i + 1}`}
      />
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i, languageProficiency);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <SuiBox mb={2}>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  <FormSelect
                    label={t('test')}
                    options={ops.test}
                    value={findSelectValue(ops.test, e.language_proficiency_test_id)}
                    onChange={(event) => {
                      handleSelect.test(event, languageProficiency, i);
                    }}
                  />
                </Grid>
                <Grid item md={2.25}>
                  <FormField
                    label={t('scheduled')}
                    type="date"
                    error={!e.schedule || e.schedule === ''}
                    onChange={(event) => {
                      setSchedule(event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.schedule)}
                  />
                </Grid>
                <Grid item md={2.25}>
                  <FormField
                    label={t('expiration')}
                    type="date"
                    onChange={(event) => {
                      setValue('expiration', event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.expiration)}
                  />
                </Grid>
                <Grid item md={2.25}>
                  <FormSelect
                    label={t('taken')}
                    textTransform="none"
                    options={yesNoStatus}
                    value={findSelectValue(yesNoStatus, e.taken)}
                    onChange={(ev) => {
                      setValue('taken', ev.value, languageProficiency, i);
                    }}
                  />
                </Grid>
                <Grid item md={2.25}>
                  <FormSelect
                    label={t('sent-to-bon')}
                    textTransform="none"
                    options={yesNoStatus}
                    value={findSelectValue(yesNoStatus, e.sent_to_bon)}
                    onChange={(ev) => {
                      setValue('sent_to_bon', ev.value, languageProficiency, i);
                    }}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <FormField
                    label={t('reading-score')}
                    placeholder={t('reading-score')}
                    error={!e.reading || e.reading === ''}
                    onChange={(event) => {
                      setValue('reading', event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.reading)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <FormField
                    label={t('writing-score')}
                    placeholder={t('writing-score')}
                    error={!e.writing || e.writing === ''}
                    onChange={(event) => {
                      setValue('writing', event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.writing)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <FormField
                    label={t('listening-score')}
                    placeholder={t('listening-score')}
                    error={!e.listening || e.listening === ''}
                    onChange={(event) => {
                      setValue('listening', event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.listening)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <FormField
                    label={t('speaking-score')}
                    placeholder={t('speaking-score')}
                    error={!e.speaking || e.speaking === ''}
                    onChange={(event) => {
                      setValue('speaking', event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.speaking)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <FormField
                    label={t('overall-score')}
                    placeholder={t('overall-score')}
                    error={!e.overall || e.overall === ''}
                    onChange={(event) => {
                      setValue('overall', event.target.value, languageProficiency, i);
                    }}
                    value={defaultValue(e.overall)}
                  />
                </Grid>
                <Grid item md={12}>
                  {e.id !== undefined && (
                    <Card sx={{ overflow: 'visible' }}>
                      <CardHeader
                        action={(
                          <IconButton onClick={openDocumentMenu}>
                            <MoreVertIcon />
                          </IconButton>
                        )}
                        title={t('documents')}
                        subheader={isDocumentsUploaded(e.documents)
                          ? t('uploaded', { keyPrefix: 'common' }) : undefined}
                      />
                      <Menu
                        anchorEl={documentMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(documentMenu)}
                        onClose={closeDocumentMenu}
                        keepMounted
                      >
                        <MenuItem
                          onClick={handleDocumentExpand}
                        >
                          {expandedDocumentState
                            ? t('collapse', { keyPrefix: 'common' })
                            : t('expand', { keyPrefix: 'common' })}
                        </MenuItem>
                      </Menu>
                      <Collapse in={expandedDocumentState} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid container spacing={3}>
                            {Object.keys(availTestDocs).length > 0 && (
                              <>
                                <Grid item md={3}>
                                  <FormSelect
                                    label={t('assign-document')}
                                    placeholder={t('select-document')}
                                    options={availTestDocs}
                                    onChange={(ev) => {
                                      setSelectedDoc(ev.value);
                                    }}
                                  />
                                </Grid>
                                <Grid item md={3} mt={5}>
                                  <IconButton
                                    aria-label="add"
                                    onClick={handleAddDocument}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item md={6} />
                              </>
                            )}
                            {Object.keys(e.documents).length > 0
                              && e.documents.map((f, j) => (
                                <Grid item md={12}>
                                  <DefaultDocumentCard
                                    key={`evaluation-document-${j}`}
                                    id={f.id}
                                    candidateId={candidateId}
                                    description={f.document.description}
                                    title={f.document.name}
                                    fileUrl={f.file_url}
                                    filesLimit={f.document.uploads_number}
                                    maxFilesSize={f.document.max_size}
                                    changeFunc={(files) => {
                                      handleUploadDocument(files, f.id);
                                    }}
                                    removeFunc={() => {
                                      handleDeleteDocument(f.id);
                                    }}
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </SuiBox>
          </CardContent>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

LanguageTestCard.propTypes = {
  i: PropTypes.number.isRequired,
  languageProficiency: PropTypes.number.isRequired,
  candidateId: PropTypes.string.isRequired,
  e: PropTypes.shape(
    {
      id: PropTypes.number,
      candidate_language_proficiency_id: PropTypes.number,
      taken: PropTypes.bool,
      schedule: PropTypes.string,
      expiration: PropTypes.string,
      reading: PropTypes.string,
      writing: PropTypes.string,
      listening: PropTypes.string,
      speaking: PropTypes.string,
      overall: PropTypes.string,
      language_proficiency_test_id: PropTypes.number,
      sent_to_bon: PropTypes.bool,
      file_url: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file_url: PropTypes.string,
          document: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            is_required: PropTypes.bool,
            uploads_number: PropTypes.number,
            auto_assigned: PropTypes.bool,
          }),
        }),
      ),
    },
  ).isRequired,
  ops: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    test: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  handleSelect: PropTypes.shape(
    {
      test: PropTypes.func,
    },
  ).isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default LanguageTestCard;
