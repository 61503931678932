import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Reducer functions
import { fetchCitiesIndex, fetchStatesIndex } from 'reducers/commonSlice';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import FormCheckbox from 'layouts/components/FormCheckbox';

// Utils
import { findSelectValue } from 'Util';

const selector = (state) => ({
  statesIndex: state.common.statesIndex,
  citiesIndex: state.common.citiesIndex,
});

function DefaultAddressCard(
  {
    i,
    a,
    ops,
    setValue,
    setDefault,
    removeFunc,
    readOnly,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.contact-info' });
  const {
    statesIndex,
    citiesIndex,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [addressMenu, setAddressMenu] = useState(null);
  const [states, setStates] = useState(undefined);
  const [cities, setCities] = useState(undefined);

  const openAddressMenu = (event) => setAddressMenu(event.currentTarget);
  const closeAddressMenu = () => setAddressMenu(null);
  const handleExpand = () => {
    closeAddressMenu();
    setExpanded(!expanded);
  };

  const handleCountry = (e) => {
    if (e.value !== undefined && e.value !== null) {
      dispatch(
        fetchStatesIndex({
          id: e.value,
          index: i,
        }),
      );

      setValue('country_id', e.value, i);
    }
  };

  const handleState = (e) => {
    if (e.value !== undefined && e.value !== null) {
      dispatch(
        fetchCitiesIndex({
          countryId: a.country_id,
          stateId: e.value,
          index: i,
        }),
      );

      setValue('state_id', e.value, i);
    }
  };

  useEffect(() => {
    if (a.country_id !== undefined && a.country_id !== null) {
      dispatch(
        fetchStatesIndex({
          id: a.country_id,
          index: i,
        }),
      );
    }

    if (a.state_id !== undefined && a.state_id !== null) {
      dispatch(
        fetchCitiesIndex({
          countryId: a.country_id,
          stateId: a.state_id,
          index: i,
        }),
      );
    }
  }, [dispatch, a, i]);

  useEffect(() => {
    setStates(statesIndex[i]);
  }, [dispatch, statesIndex, i]);

  useEffect(() => {
    setCities(citiesIndex[i]);
  }, [dispatch, citiesIndex, i]);

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openAddressMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${t('address')} ${i + 1}`}
      />
      <Menu
        anchorEl={addressMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(addressMenu)}
        onClose={closeAddressMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SuiBox mb={2} m={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <FormField
                  label={t('address')}
                  error={!a.address || a.address === ''}
                  onChange={(e) => { setValue('address', e.target.value, i); }}
                  value={a.address}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={8} md={3}>
                <FormSelect
                  label={t('type')}
                  options={ops.addressTypes}
                  id={`address_type[${i}]`}
                  value={findSelectValue(ops.addressTypes, a.address_type_id)}
                  onChange={(e) => { setValue('address_type_id', e.value, i); }}
                  readOnly={readOnly}
                />
              </Grid>
              {!readOnly && (
                <Grid item xs={1} md={1} pb={1}>
                  <FormCheckbox
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                    checked={a.default}
                    onChange={(e) => { setDefault(e, i); }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label=""
                  />
                </Grid>
              )}
              <Grid item xs={2} md={1} mt={3}>
                <SuiTypography variant="caption">
                  {a.default ? t('default') : ''}
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md={3} pt={1}>
                <FormSelect
                  label={t('country')}
                  options={ops.countries}
                  value={findSelectValue(ops.countries, a.country_id)}
                  onChange={(e) => { handleCountry(e); }}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                <FormSelect
                  label={t('state-province')}
                  options={states}
                  id={`state[${i}]`}
                  value={states !== undefined ? findSelectValue(states, a.state_id) : undefined}
                  onChange={(e) => { handleState(e); }}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                <FormSelect
                  label={t('city')}
                  options={cities}
                  id={`city[${i}]`}
                  value={cities !== undefined ? findSelectValue(cities, a.city_id) : undefined}
                  onChange={(e) => { setValue('city_id', e.value, i); }}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                <FormField
                  label={t('postal-code')}
                  onChange={(e) => { setValue('postal_code', e.target.value, i); }}
                  value={a.postal_code}
                  readOnly={readOnly}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultAddressCard.defaultProps = {
  readOnly: false,
};

DefaultAddressCard.propTypes = {
  i: PropTypes.number.isRequired,
  a: PropTypes.shape(
    {
      id: PropTypes.number,
      address: PropTypes.string,
      address_type_id: PropTypes.number,
      default: PropTypes.bool,
      country_id: PropTypes.number,
      state_id: PropTypes.number,
      city_id: PropTypes.number,
      postal_code: PropTypes.string,
    },
  ).isRequired,
  ops: PropTypes.shape({
    addressTypes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  setDefault: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default DefaultAddressCard;
