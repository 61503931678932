import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';

// Components
import DefaultModal from 'layouts/components/Modal/DefaultModal';

function BaseParameters({
  children,
  parameters,
  style,
  openParameters,
  closeParamsFunc,
  handleParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });

  const saveParameters = () => {
    handleParamsFunc(parameters);
    closeParamsFunc();
  };

  return (
    <DefaultModal
      openModal={openParameters}
      closeFunc={closeParamsFunc}
      style={style}
    >
      <Card sx={{ overflow: 'visible' }}>
        <CardContent>
          {children}
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Grid item>
              <SuiButton variant="text" color="info" onClick={saveParameters}>{t('save')}</SuiButton>
              <SuiButton variant="text" color="error" onClick={closeParamsFunc}>{t('close')}</SuiButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </DefaultModal>
  );
}

BaseParameters.defaultProps = {
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 1,
  },
};

BaseParameters.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  parameters: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
};

export default BaseParameters;
