import Service from './service';

class AgreementService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getAgreements() {
    const response = await this.api
      .get(
        'agreements',
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getAgreement(id) {
    const response = await this.api
      .get(
        `agreements/${id}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getAgreementVersion(id, version) {
    const response = await this.api
      .get(
        `agreements/${id}/version/${version}`,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createAgreement(
    name,
    agreementTypeId,
    language,
    expires,
    expiration,
    agreementTagList,
    agreementVersions,
  ) {
    const data = {
      agreement: {
        name,
        agreement_type_id: agreementTypeId,
        language,
        expires,
        expiration,
        agreement_tag_list: agreementTagList,
        agreement_versions_attributes: agreementVersions,
      },
    };

    const response = await this.api
      .post(
        'agreements',
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateAgreement(
    id,
    name,
    agreementTypeId,
    language,
    expires,
    expiration,
    agreementTagList,
    agreementVersions,
  ) {
    const data = {
      name,
      agreement_type_id: agreementTypeId,
      language,
      expires,
      expiration,
      agreement_tag_list: agreementTagList,
      agreement_versions_attributes: agreementVersions,
    };

    const response = await this.api
      .put(
        `agreements/${id}`,
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteAgreement(id) {
    const response = await this.api
      .delete(
        `agreements/${id}`,
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new AgreementService();
