import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Reducers
import { fetchInvoiceLimited } from 'reducers/invoicesSlice';

// Payment form page components
import InvoicePayment from 'layouts/invoices/components/Payment';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// @mui material components
import Grid from '@mui/material/Grid';

// Functions
import { currencyFormatter } from 'Util';

// Components
// Images
import OverdueIcon from 'layouts/components/Icons/OverdueIcon';
import Error404 from 'layouts/error/404';
import PageLayout from '../../../components/LayoutContainers/PageLayout';
import Footer from '../../../components/Footer';
import logoNAS from '../../../../assets/images/nas-logo.png';

const selector = (state) => ({
  invoiceInfo: state.invoice.invoiceInfo,
});

function PaymentForm() {
  const { t } = useTranslation('translation', { keyPrefix: 'payments' });
  const { invoiceInfo } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      fetchInvoiceLimited({
        id,
      }),
    );
  }, [dispatch, id]);

  return (
    <>
      {invoiceInfo.status !== 'draft'
        && invoiceInfo.status !== 'paid'
        && invoiceInfo.status !== 'void'
        && (
          <PageLayout>
            <Grid
              container
              direction="row"
              padding={3}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid item md={12}>
                <SuiBox
                  component="img"
                  src={logoNAS}
                  width="10%"
                  p={1}
                  mb={1}
                />
              </Grid>
              <Grid item md={12}>
                <SuiTypography variant="h1">{t('invoice-payment')}</SuiTypography>
              </Grid>
              <Grid item md={12}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item md={3} mr={5}>
                    <SuiBox
                      width="100%"
                      textAlign={{
                        xs: 'left',
                        md: 'right',
                      }}
                      mt={6}
                    >
                      <SuiBox mb={1}>
                        <SuiTypography variant="body2" color="secondary">
                          {`${t('invoice')} # ${invoiceInfo.id}`}
                          <br />
                          {`${t('date')}: ${invoiceInfo.date}`}
                        </SuiTypography>
                        <SuiTypography variant="h4" fontWeight="medium">
                          {`${t('due')} ${invoiceInfo.due}`}
                        </SuiTypography>
                        <SuiTypography variant="h1" fontWeight="medium">
                          {`${t('total')} ${currencyFormatter.format(invoiceInfo.total)}`}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mt={1}>
                        <SuiTypography variant="h2" fontWeight="medium">
                          {invoiceInfo.status === 'overdue' && (
                            <OverdueIcon size="80" color="error" />
                          )}
                        </SuiTypography>
                      </SuiBox>
                    </SuiBox>
                  </Grid>
                  <Grid item md={6}>
                    <SuiBox>
                      <InvoicePayment isExternal />
                    </SuiBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Footer />
          </PageLayout>
        )}
      {(invoiceInfo.status === 'draft'
        || invoiceInfo.status === 'paid'
        || invoiceInfo.status === 'void') && (
        <Error404 />
      )}
    </>
  );
}

export default PaymentForm;
