import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @mui material components
import { Label } from '@material-ui/icons';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// DevExtreme components
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Format,
  Legend,
  Series,
  Tooltip,
  Title,
  ValueAxis,
} from 'devextreme-react/chart';

function SummaryChart({ transactions }) {
  const { t } = useTranslation('translation', { keyPrefix: 'accounts' });

  const [charData, setCharData] = useState([]);

  useEffect(() => {
    if (transactions !== undefined) {
      const trans = [];

      const cumulativeVals = (data) => {
        let cumInvoice = 0;
        let cumPayment = 0;
        let cumExpense = 0;
        return data.map((item) => {
          if (item.type === 'payment') {
            cumPayment += item.val;
          }
          if (item.type === 'expense') {
            cumExpense += item.val;
          }
          if (item.type === 'invoice') {
            cumInvoice += item.val;
          }
          return {
            type: item.type,
            date: item.date.toLocaleDateString(),
            val: item.val,
            paymentCum: cumPayment,
            expenseCum: cumExpense,
            invoiceCum: cumInvoice,
          };
        });
      };

      const removeDuplicate = (data) => data
        .filter((obj, index, self) => index === self.findIndex((o) => o.date === obj.date));

      transactions.forEach((tr) => {
        trans.push({ type: tr.doc_type, date: new Date(tr.doc_date), val: parseFloat(tr.total) });
      });
      setCharData(removeDuplicate(cumulativeVals(trans.reverse())));
    }
  }, [t, transactions]);

  const customizeTooltip = (pointInfo) => {
    const html = `
      <div>
        <div style="margin-bottom: 5px; font-size: 16px; font-weight: 500; padding-bottom: 5px; border-bottom: 1px solid #c5c5c5;">
            ${pointInfo.argumentText}
        </div>
        <div style="width: 170px;">
          <div style="width: 170px; font-weight: normal; opacity: 0.6; display: inline-block; line-height: 1.5; padding-right: 10px; width: 126px;">
            <span>${pointInfo.points[0].seriesName}</span>
          </div>
          <div style="width: 170px; display: inline-block; line-height: 1.5; width: 30px;">
            <span>${pointInfo.points[0].valueText}</span>
          </div>
          <div style="display: ${pointInfo.points[1] === undefined ? 'none' : 'block'}">
            <div style="width: 170px; font-weight: normal; opacity: 0.6; display: inline-block; line-height: 1.5; padding-right: 10px; width: 126px;">
              <span>${pointInfo.points[1] !== undefined ? pointInfo.points[1].seriesName : ''}</span>
            </div>
            <div style="width: 170px; display: inline-block; line-height: 1.5; width: 30px;">
              <span>${pointInfo.points[1] !== undefined ? pointInfo.points[1].valueText : ''}</span>
            </div>
          </div>
          <div style="display: ${pointInfo.points[2] === undefined ? 'none' : 'block'}">
            <div style="width: 170px; font-weight: normal; opacity: 0.6; display: inline-block; line-height: 1.5; padding-right: 10px; width: 126px;">
              <span>${pointInfo.points[2] !== undefined ? pointInfo.points[2].seriesName : ''}</span>
            </div>
            <div style="width: 170px; display: inline-block; line-height: 1.5; width: 30px;">
              <span>${pointInfo.points[2] !== undefined ? pointInfo.points[2].valueText : ''}</span>
            </div>
          </div>
          <div style="display: ${pointInfo.points[3] === undefined ? 'none' : 'block'}">
            <div style="width: 170px; font-weight: normal; opacity: 0.6; display: inline-block; line-height: 1.5; padding-right: 10px; width: 126px;">
              <span>${pointInfo.points[3] !== undefined ? pointInfo.points[3].seriesName : ''}</span>
            </div>
            <div style="width: 170px; display: inline-block; line-height: 1.5; width: 30px;">
              <span>${pointInfo.points[3] !== undefined ? pointInfo.points[3].valueText : ''}</span>
            </div>
          </div>
          <div style="display: ${pointInfo.points[4] === undefined ? 'none' : 'block'}">
            <div style="width: 170px; font-weight: normal; opacity: 0.6; display: inline-block; line-height: 1.5; padding-right: 10px; width: 126px;">
              <span>${pointInfo.points[4] !== undefined ? pointInfo.points[4].seriesName : ''}</span>
            </div>
            <div style="width: 170px; display: inline-block; line-height: 1.5; width: 30px;">
              <span>${pointInfo.points[4] !== undefined ? pointInfo.points[4].valueText : ''}</span>
            </div>
          </div>
        </div>
      </div>`;

    return {
      html,
    };
  };

  return (
    <Card sx={{ display: 'flex', padding: '5px' }}>
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        p={1}
      >
        <Grid item md={12}>
          <Chart dataSource={charData} id="summary">
            <CommonSeriesSettings argumentField="date" type="spline" />
            <Series name={t('invoices')} valueField="invoiceCum" />
            <Series name={t('expenses')} valueField="expenseCum" />
            <Series name={t('payments')} valueField="paymentCum" />
            <ArgumentAxis>
              <Label overlappingBehavior="stagger" />
            </ArgumentAxis>
            <Tooltip enabled shared customizeTooltip={customizeTooltip} />
            <Legend verticalAlignment="top" horizontalAlignment="center" />
            <ArgumentAxis>
              <Label format="shortDate" />
            </ArgumentAxis>
            <ValueAxis tickInterval={1}>
              <Title text="US dollars" />
              <Label>
                <Format precision={0} type="currency" />
              </Label>
            </ValueAxis>
          </Chart>
        </Grid>
      </Grid>
    </Card>
  );
}

SummaryChart.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape(
      {
        doc_type: PropTypes.string,
        doc_number: PropTypes.number,
        status: PropTypes.string,
        doc_date: PropTypes.string,
        candidate_id: PropTypes.string,
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        total: PropTypes.string,
        vendor: PropTypes.string,
      },
    ),
  ).isRequired,
};

export default SummaryChart;
