import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Components
import BaseParameters from 'layouts/reports/components/BaseParameters';
import FormField from 'layouts/components/FormField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 1,
};

function DueInvoicesParameters({
  parameters,
  handleParamsFunc,
  openParameters,
  closeParamsFunc,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'reports' });
  const dispatch = useDispatch();

  const [params, setParams] = useState(parameters);
  const [selectedDate, setSelectedDate] = useState([]);

  useEffect(() => {
    if (parameters !== undefined) {
      const { date } = parameters;
      if (date !== undefined) {
        setSelectedDate(`${date.substring(0, 4)}-${date.substring(5, 7)}-${date.substring(8, 10)}`);
      }
    }
  }, [dispatch, parameters]);

  const handleDate = (e) => {
    setSelectedDate(e.target.value);
    setParams({ date: e.target.value });
  };

  return (
    <BaseParameters
      parameters={params}
      style={style}
      openParameters={openParameters}
      closeParamsFunc={closeParamsFunc}
      handleParamsFunc={(p) => handleParamsFunc(p)}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item md={12}>
          <FormField
            label={t('date')}
            value={selectedDate}
            type="date"
            onChange={handleDate}
          />
        </Grid>
      </Grid>
    </BaseParameters>
  );
}

DueInvoicesParameters.propTypes = {
  parameters: PropTypes.shape({
    date: PropTypes.string,
  }).isRequired,
  handleParamsFunc: PropTypes.func.isRequired,
  openParameters: PropTypes.bool.isRequired,
  closeParamsFunc: PropTypes.func.isRequired,
};

export default DueInvoicesParameters;
