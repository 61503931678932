import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Components
import FormField from 'layouts/components/FormField';

// Functions
import { defaultValue } from 'Util';

const selector = (state) => ({
  userProfile: state.user.userProfile,
});

function BasicInfo({ setValue }) {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const { userProfile } = useSelector(selector, shallowEqual);

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <SuiBox p={3}>
        <SuiTypography variant="h5">Basic Info</SuiTypography>
      </SuiBox>
      <SuiBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <FormField
              label={t('first-name', { keyPrefix: 'common' })}
              value={userProfile.first_name}
              onChange={(e) => setValue('first_name', e.target.value)}
            />
          </Grid>
          <Grid item md={6}>
            <FormField
              label={t('last-name', { keyPrefix: 'common' })}
              value={userProfile.last_name}
              onChange={(e) => setValue('last_name', e.target.value)}
            />
          </Grid>
          <Grid item md={12}>
            <FormField
              label={t('e-mail', { keyPrefix: 'common' })}
              type="email"
              inputProps={{ readOnly: true }}
              value={defaultValue(userProfile.email)}
            />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

BasicInfo.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default BasicInfo;
